export default function Cereali({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m340.03,196.54c37.66-67.54-12.75-151.19-89.27-151.52-77.56-.34-128.54,82.81-90.75,151.56,20.45,37.21,21.21,70.13-.01,106.87-24.72,42.8-12.21,97.88,25.7,128.8,37.16,30.3,91.55,30.32,128.69.04,37.69-30.73,50.28-86.2,25.63-128.92-21.2-36.75-20.67-69.72.01-106.82Zm-115.75-72.08c0,7.02-5.69,12.71-12.71,12.71s-12.71-5.69-12.71-12.71,5.69-12.71,12.71-12.71,12.71,5.69,12.71,12.71Zm41.17,49.68c0,7.02-5.69,12.71-12.71,12.71s-12.71-5.69-12.71-12.71,5.69-12.71,12.71-12.71,12.71,5.69,12.71,12.71Zm38.33-49.68c0,7.02-5.69,12.71-12.71,12.71s-12.71-5.69-12.71-12.71,5.69-12.71,12.71-12.71,12.71,5.69,12.71,12.71Zm-23.03,253.09c0-7.02,5.69-12.71,12.71-12.71s12.71,5.69,12.71,12.71-5.69,12.71-12.71,12.71-12.71-5.69-12.71-12.71Zm-41.17-49.68c0-7.02,5.69-12.71,12.71-12.71s12.71,5.69,12.71,12.71-5.69,12.71-12.71,12.71-12.71-5.69-12.71-12.71Zm-38.33,49.68c0-7.02,5.69-12.71,12.71-12.71s12.71,5.69,12.71,12.71-5.69,12.71-12.71,12.71-12.71-5.69-12.71-12.71Z"
				style={style}
			/>
		</svg>
	);
}
