import { MapContainer } from "react-leaflet";
import { Link } from "react-router-dom";
import ShopsMap from "../../../components/ShopsMap";
import Label from "../../../components/Label";
import "./style.css";
import { useContext } from "react";
import { AppContext } from "../../../App";

export default function Template1({ shops }) {
	const { getTranslatedString } = useContext(AppContext);

	return (
		<div className="page">
			<h1 id="page-header">
				<Label number={29} />
			</h1>
			<div className={"container shops shops-" + (shops.length < 3 ? shops.length : 3)}>
				{shops.map((shop, i) => (
					<div key={i} className="shop-box">
						<div className="box-header">
							<div className="address">
								{shop.address +
									" " +
									shop.number +
									", " +
									shop.zipcode +
									", " +
									shop.city +
									" (" +
									shop.province +
									")"}
							</div>
							<div className="title">{shop.name}</div>
						</div>
						<div className="box">
							<div className="img">{shop.img && <img src={shop.img} />}</div>
							<div className="contacts">
								{shop.email && (
									<div>
										<a href={"mailto:" + shop.email}>{shop.email}</a>
									</div>
								)}
								{shop.phone && (
									<div>
										<a href={"tel:" + shop.phone}>{shop.phone}</a>
									</div>
								)}
							</div>
							{shop.times_text && (
								<div className="times">
									<div className="title">
										<Label number={148} />
									</div>
									<div
										dangerouslySetInnerHTML={{
											__html: getTranslatedString(shop, "times_text"),
										}}
									/>
								</div>
							)}
							<div className="buttons">
								{(shop.delivery == 1 || shop.takeaway == 1) && (
									<Link to="/store">
										<Label number={1} />
									</Link>
								)}
								{shop.active_reservations == 1 && (
									<>
										{!shop.reservations_ext_url ? (
											<Link to="/booking">
												<Label number={2} />
											</Link>
										) : (
											<a href={shop.reservations_ext_url} target="_blank">
												<Label number={2} />
											</a>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				))}
			</div>
			<div className="shop-page-footer">
				<div id="shop-map">
					<MapContainer
						center={shops[0].coord.split(",")}
						zoom={14}
						scrollWheelZoom={false}
					>
						<ShopsMap shops={shops} />
					</MapContainer>
				</div>
			</div>
		</div>
	);
}
