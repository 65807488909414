import Label from "./Label";

function ProductLabels({ product, discounted = false }) {
	return (
		<div className="badge-container">
			{product.new == 1 && (
				<div className="label-new">
					<Label number={24} />
				</div>
			)}
			{product.best_sell == 1 && (
				<div className="label-sell">
					<Label number={25} />
				</div>
			)}
			{product.vegan == 1 && (
				<div className="label-vegan">
					<Label number={26} />
				</div>
			)}
			{product.gluten_free == 1 && (
				<div className="label-gluten">
					<Label number={27} />
				</div>
			)}
			{product.disabled == 1 && (
				<div className="label-available">
					<Label number={28} />
				</div>
			)}
			{discounted == 1 && (
				<div className="label-promo">
					<Label number={174} />
				</div>
			)}
			{product.custom_label == 1 && (
				<div className="label-custom">
					<Label number={179} />
				</div>
			)}
		</div>
	);
}

export default ProductLabels;
