import { useContext, useEffect, useState } from "react";
import { TileLayer, Tooltip, Marker, useMap } from "react-leaflet";

function ShopMap({ shop }) {
	const map = useMap();

	return (
		<>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			<Marker position={shop.coord.split(",")}></Marker>
		</>
	);
}

export default ShopMap;
