import { useEffect, useState } from "react";
import moment from "moment/moment";
import "./style.css";

export default function Countdown({ timeTillDate, timeFormat }) {
	const [days, setDays] = useState(undefined);
	const [hours, setHours] = useState(undefined);
	const [minutes, setMinutes] = useState(undefined);
	const [seconds, setSeconds] = useState(undefined);

	useEffect(() => {
		const timeout = setInterval(() => {
			const then = moment(timeTillDate, timeFormat);
			const now = moment();
			const countdown = moment(then - now);
			const days = countdown.format("D");
			const hours = countdown.format("HH");
			const minutes = countdown.format("mm");
			const seconds = countdown.format("ss");
			setDays(days);
			setHours(hours);
			setMinutes(minutes);
			setSeconds(seconds);
		}, 1000);

		return () => clearTimeout(timeout);
	}, []);

	const SVGCircle = ({ radius }) => (
		<svg className="countdown-svg">
			<path
				fill="none"
				stroke="#333"
				strokeWidth="4"
				d={describeArc(50, 50, 48, radius, 360)}
			/>
		</svg>
	);
	function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
		var angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

		return {
			x: centerX + radius * Math.cos(angleInRadians),
			y: centerY + radius * Math.sin(angleInRadians),
		};
	}

	function describeArc(x, y, radius, startAngle, endAngle) {
		var start = polarToCartesian(x, y, radius, endAngle);
		var end = polarToCartesian(x, y, radius, startAngle);

		var largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

		var d = ["M", start.x, start.y, "A", radius, radius, 0, largeArcFlag, 0, end.x, end.y].join(
			" "
		);

		return d;
	}

	function mapNumber(number, in_min, in_max, out_min, out_max) {
		return ((number - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
	}

	return (
		<div className="countdown-wrapper">
			{days && (
				<div className="countdown-item">
					<SVGCircle radius={mapNumber(days, 30, 0, 0, 360)} />
					{days}
					<span>days</span>
				</div>
			)}
			{hours && (
				<div className="countdown-item">
					<SVGCircle radius={mapNumber(hours, 24, 0, 0, 360)} />
					{hours}
					<span>hours</span>
				</div>
			)}
			{minutes && (
				<div className="countdown-item">
					<SVGCircle radius={mapNumber(minutes, 60, 0, 0, 360)} />
					{minutes}
					<span>minutes</span>
				</div>
			)}
			{seconds && (
				<div className="countdown-item">
					<SVGCircle radius={mapNumber(seconds, 60, 0, 0, 360)} />
					{seconds}
					<span>seconds</span>
				</div>
			)}
		</div>
	);
}
