const currencyFormat = (number, curr = "EUR") => {
	const [currency, cents] = Number(number).toFixed(2).toString().split(".");
	let res = `${currency.replace(/\B(?=(\d{3})+(?!\d))/g, ".")},${cents}`;
	if (!curr || curr == "EUR") res += " €";
	if (curr == "CHF") res += " CHF";
	if (curr == "DKK") res += " kr";
	//console.log("currencyFormat", number, res);
	return res;
};

const setCookie = (cname, cvalue, exdays = 30) => {
	if (exdays) {
		const d = new Date();
		d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
		let expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	} else {
		document.cookie = cname + "=" + cvalue + ";path=/";
	}
};

const getCookie = (cname) => {
	let name = cname + "=";
	let decodedCookie = decodeURIComponent(document.cookie);
	let ca = decodedCookie.split(";");
	for (let i = 0; i < ca.length; i++) {
		let c = ca[i];
		while (c.charAt(0) == " ") {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
};

const formatOrderStatus = (status) => {
	if (status == "unconfirmed") return 65;
	if (status == "cancel_request") return 89;
	if (status == "archived") return 66;
	if (status == "confirmed") return 67;
	if (status == "filled") return 68;
	return status;
};

const formatOrderDeliveryType = (deliveryType) => {
	if (deliveryType == "delivery") return 7;
	if (deliveryType == "takeaway") return 8;
	if (deliveryType == "shop") return 9;
	return deliveryType;
};

const scrollProducts = (category_id) => {
	console.log("scrollProducts");
	const scrollContainer = document.getElementById("main");
	const header = document.getElementById("header");
	const menu = document.getElementsByClassName("categories-menu-1-container")[0];
	const element = document.getElementById("category-" + category_id);

	const menuHeight = menu ? menu.offsetHeight : 0;
	console.log(
		element.offsetTop,
		header.offsetHeight,
		menuHeight,
		element.offsetTop - header.offsetHeight - menuHeight
	);
	if (element) {
		// 👇 Will scroll smoothly to the top of the next section
		//element.scrollIntoView({ top: 120, behavior: "smooth" });

		scrollContainer.scrollTo({
			behavior: "smooth",
			top: element.offsetTop - header.offsetHeight - menuHeight,
		});
	}
};

const hasRemovableIngredients = (product) => {
	return product.ingredients.filter((i) => i.removable == 1).length > 0;
};

const textLabel = (labels, number, lang) => {
	if (labels) {
		let l = labels.filter((l) => {
			return l.id === number;
		});
		if (l.length > 0) return l[0][lang];
	}
};

export {
	currencyFormat,
	getCookie,
	setCookie,
	formatOrderStatus,
	formatOrderDeliveryType,
	scrollProducts,
	hasRemovableIngredients,
	textLabel,
};
