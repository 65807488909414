function PlusMinus({ qty, onCange, disabled = false, qtyValue = false }) {
	return (
		<div className="plus-minus">
			<button onClick={() => onCange(parseInt(qtyValue ? qtyValue : qty) - 1)}>
				<i className="fas fa-minus"></i>
			</button>
			<div>{qty}</div>
			{!disabled ? (
				<button onClick={() => onCange(parseInt(qtyValue ? qtyValue : qty) + 1)}>
					<i className="fas fa-plus"></i>
				</button>
			) : (
				<button className="disabled">
					<i className="fas fa-plus"></i>
				</button>
			)}
		</div>
	);
}

export default PlusMinus;
