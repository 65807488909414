import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import Label from "./Label";

function ExpireBox({ block = false }) {
	const { settings, cart, loadCart } = useContext(AppContext);
	const [expire, setExpire] = useState(false);

	useEffect(() => {
		calcTime();
	}, [cart]);

	useEffect(() => {
		let expireTimeout = null;
		if (expire !== false) {
			if (expire > 0) {
				console.log("Set expire timeout");
				expireTimeout = expire
					? window.setTimeout(() => setExpire(expire - 1), 60 * 1000)
					: null;
			} else {
				loadCart();
			}
		}
		return () => clearTimeout(expireTimeout);
	}, [expire]);

	const calcTime = () => {
		//console.log("calcTime");
		if (
			cart &&
			settings.orders?.show_expiring == 1 &&
			cart.availables_delivery_times.length > 0
		) {
			let res = false;
			for (const time of cart.availables_delivery_times) {
				if (time.value == cart.delivery_date) {
					//console.log(time);
					res = time.expire;
				}
			}
			setExpire(res);
		}
	};

	if (settings.orders?.show_expiring != 1 || !expire || expire > 30) return null;

	return (
		<span className={"expire-box" + (block ? " block" : "")}>
			<Label number={169} />
			{" " + expire + " "}
			<Label number={170} />
		</span>
	);
}

export default ExpireBox;
