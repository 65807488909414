import { useContext } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../App";
import ios from "../images/ios.svg";
import google from "../images/google.svg";
import CmsMenu from "./CmsMenu";
import Label from "./Label";

function Footer() {
	const { settings, shops, pages, menus, setCookieSettingsPopupVisible } = useContext(AppContext);

	return (
		<div id="footer">
			<div className="mainfooter">
				{settings.website.logo_footer && (
					<div className="footer-logo">
						<img src={settings.website.logo_footer} />
					</div>
				)}
				<div className="mainfooter-info">
					<div className="menu-footer menu-footer-1">
						{menus.filter((m) => m.section == "footer1").length > 0 && (
							<>
								{menus.map(
									(menu, i) =>
										menu.section == "footer1" && (
											<CmsMenu
												data={menu}
												key={i}
												type="bubble"
												className=""
											/>
										)
								)}
							</>
						)}
					</div>
					<div className="footer-shops">
						{shops.map((shop, i) => (
							<div key={i}>
								<Link key={i} className="" to={"/shops/" + shop.id}>
									<div className="title">{shop.city}</div>
									<div>
										<div>
											{shop.address}, {shop.number}
										</div>
										<div>{shop.phone && "Tel: " + shop.phone}</div>
										<div>{shop.email && "Email: " + shop.email}</div>
									</div>
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="prefooter">
				<div className="socials">
					{settings.socials.facebook && (
						<a href={settings.socials.facebook} target="_blank">
							<i className="fab fa-facebook"></i>
						</a>
					)}
					{settings.socials.twitter && (
						<a href={settings.socials.twitter} target="_blank">
							<i className="fab fa-twitter"></i>
						</a>
					)}
					{settings.socials.instagram && (
						<a href={settings.socials.instagram} target="_blank">
							<i className="fab fa-instagram"></i>
						</a>
					)}
					{settings.socials.foursquare && (
						<a href={settings.socials.foursquare} target="_blank">
							<i className="fab fa-foursquare"></i>
						</a>
					)}
					{settings.socials.pinterest && (
						<a href={settings.socials.pinterest} target="_blank">
							<i className="fab fa-pinterest"></i>
						</a>
					)}
					{settings.socials.whatsapp && (
						<a href={settings.socials.whatsapp} target="_blank">
							<i className="fab fa-whatsapp"></i>
						</a>
					)}
				</div>

				<div className="menu-prefooter menu-footer-2">
					{menus.filter((m) => m.section == "footer2").length > 0 && (
						<>
							{menus.map(
								(menu, i) =>
									menu.section == "footer2" && (
										<CmsMenu data={menu} key={i} type="bubble" className="" />
									)
							)}
						</>
					)}
				</div>
			</div>
			<div className="subfooter">
				<div className="info">
					{settings.global.company && (
						<div className="title">{settings.global.company}</div>
					)}
					{settings.global.vat && <div>P.Iva {settings.global.vat}</div>}
					{settings.global.taxcode && <div>C.F. {settings.global.taxcode}</div>}
					{settings.global.capital && (
						<div>Capitale Sociale: {settings.global.capital}</div>
					)}
					{settings.global.rea && <div>Numero REA: {settings.global.rea}</div>}
					{pages.map(
						(page, i) =>
							page.menu_footer == 1 && (
								<div key={i}>
									<Link className="" to={"/cms/" + page.id}>
										{page.title}
									</Link>
								</div>
							)
					)}
					{settings && settings.website.disable_cookies_banner != 1 && (
						<div>
							<Link className="" onClick={() => setCookieSettingsPopupVisible(true)}>
								<Label number={195} />
							</Link>
						</div>
					)}
					<div>
						<a href="https://www.yellgo.it" target="_blank">
							Powered by YellGo
						</a>
					</div>
				</div>
				{(settings.website.ios_app_link || settings.website.android_app_link) && (
					<div className="apps">
						{settings.website.ios_app_link && (
							<div>
								<a href={settings.website.ios_app_link} target="_blank">
									<img src={ios} />
								</a>
							</div>
						)}
						{settings.website.android_app_link && (
							<div>
								<a href={settings.website.android_app_link} target="_blank">
									<img src={google} />
								</a>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
}

export default Footer;
