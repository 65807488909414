import { useContext } from "react";
import { AppContext } from "../App";
import Label from "./Label";

function Marketing({ marketing, setMarketing, newsletter, setNewsletter, checkUser = true }) {
	const { settings, user, setPagePopup } = useContext(AppContext);

	return (
		<div className="marketing">
			{settings.mandant.newsletter == 1 &&
				settings.newsletter?.active == 1 &&
				(!checkUser || user.newsletter == 0) && (
					<div className="checkbox">
						<input
							id="newsletter"
							type="checkbox"
							value={newsletter}
							checked={newsletter}
							onChange={() => setNewsletter(newsletter ? false : true)}
						/>
						<label htmlFor="newsletter">
							<Label number={106} />
							<a
								onClick={(e) => {
									e.preventDefault();
									setPagePopup(settings.orders.privacy_page_id);
								}}
							>
								<Label number={107} />
							</a>
						</label>
					</div>
				)}
			{settings.mandant.promo == 1 && (!checkUser || user.marketing == 0) && (
				<div className="checkbox">
					<input
						id="marketing"
						type="checkbox"
						value={marketing}
						checked={marketing}
						onChange={() => setMarketing(marketing ? false : true)}
					/>
					<label htmlFor="marketing">
						<Label number={106} />
						<a
							onClick={(e) => {
								e.preventDefault();
								setPagePopup(settings.orders.privacy_page_id);
							}}
						>
							<Label number={108} />
						</a>
					</label>
				</div>
			)}
		</div>
	);
}

export default Marketing;
