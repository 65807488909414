import { useContext, useEffect } from "react";
import { AppContext } from "../../App";
import api from "../api";

function Satispay({ onCancel }) {
	const { settings, cart, setLoading, user } = useContext(AppContext);
	const demo =
		window.location.hostname === "localhost" || window.location.hostname.includes(".localhost")
			? 1
			: 0;

	useEffect(() => {
		pay();
	}, []);

	const pay = async () => {
		setLoading(true);
		const response = await api.get(
			"/plugins/satispay2/init_payment.php?mandant_id=" +
				settings.mandant.id +
				"&id=" +
				cart.customer_id +
				"&amount=" +
				cart.total +
				"&origin=website&demo=" +
				demo +
				"&logged=" +
				(user ? 1 : 0)
		);
		console.log(response);
		if (response.success == 0) {
			setLoading(false);
			alert(response.error);
		} else {
			if (response.data.callback_url) window.open(response.data.callback_url);
			window.location = response.data.redirect_url;
		}
	};

	const findPaymentName = (paymentId) => {
		for (const payment_method of cart.availables_payment_methods) {
			if (payment_method.id == paymentId) return payment_method.label;
		}
	};

	return (
		<div className="buttons-group">
			<button
				className="button button-primary"
				onClick={pay}
				style={{
					backgroundColor: settings.selfordering.color1,
					color: settings.selfordering.color2,
				}}
			>
				{findPaymentName(8)}
			</button>
			<button className="button" type="button" onClick={onCancel}>
				Annulla
			</button>
		</div>
	);
}

export default Satispay;
