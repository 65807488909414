import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { AppContext } from "../App";
import { MapContainer } from "react-leaflet";
import Label from "../components/Label";
import ShopMap from "../components/ShopMap";

function Shop() {
	const { shops, getTranslatedString } = useContext(AppContext);
	const [shop, setShop] = useState(false);
	let params = useParams();

	useEffect(() => {
		const p = shops.find((shop) => shop.id == params.id);
		//console.log(p);
		setShop(p);
	}, [params]);

	if (!shop) return null;

	return (
		<div className="page shop-page">
			<h1 id="page-header">{shop.name}</h1>
			<div className="content">
				<div className="left">
					{shop.description && (
						<div
							className="description"
							dangerouslySetInnerHTML={{
								__html: getTranslatedString(shop, "description"),
							}}
						/>
					)}
					<div className="buttons">
						{(shop.delivery == 1 || shop.takeaway == 1) && (
							<Link to="/store">
								<Label number={1} />
							</Link>
						)}
						{shop.active_reservations == 1 && (
							<>
								{!shop.reservations_ext_url ? (
									<Link to="/booking">
										<Label number={2} />
									</Link>
								) : (
									<a href={shop.reservations_ext_url} target="_blank">
										<Label number={2} />
									</a>
								)}
							</>
						)}
					</div>
				</div>
				<div className="right">
					<div className="columns">
						{shop.address && (
							<div className="shop-page-card">
								<div className="icon">
									<i className="fas fa-map-marker-alt" />
								</div>
								<div className="title">
									<Label number={12} />
								</div>
								<div className="label">
									{shop.address + " " + shop.number}
									<br />
									{shop.zipcode + " " + shop.city + " (" + shop.province + ")"}
								</div>
							</div>
						)}
						{(shop.phone || shop.email) && (
							<div className="shop-page-card">
								<div className="icon">
									<i className="fas fa-phone" />
								</div>
								<div className="title">
									<Label number={147} />
								</div>
								<div className="label">
									{shop.email && (
										<div>
											<a href={"mailto:" + shop.email}>{shop.email}</a>
										</div>
									)}
									{shop.phone && (
										<div>
											<a href={"tel:" + shop.phone}>{shop.phone}</a>
										</div>
									)}
								</div>
							</div>
						)}
						{shop.times_text && (
							<div className="shop-page-card">
								<div className="icon">
									<i className="fas fa-clock" />
								</div>
								<div className="title">
									<Label number={148} />
								</div>
								<div
									className="label"
									dangerouslySetInnerHTML={{
										__html: getTranslatedString(shop, "times_text"),
									}}
								/>
							</div>
						)}
					</div>
					{shop.additional_info && (
						<div className="more-info">
							<div className="icon">
								<i className="fas fa-info" />
							</div>
							<div
								className="text"
								dangerouslySetInnerHTML={{
									__html: getTranslatedString(shop, "additional_info"),
								}}
							/>
						</div>
					)}
				</div>
			</div>
			{shop.gallery && shop.gallery.length > 0 && (
				<div className="shop-gallery">
					{shop.gallery.map((img, i) => (
						<img key={i} src={img} />
					))}
				</div>
			)}
			<div className="shop-page-footer">
				{shop.coord && (
					<div id="shop-map">
						<MapContainer
							center={shop.coord.split(",")}
							zoom={14}
							scrollWheelZoom={false}
						>
							<ShopMap shop={shop} />
						</MapContainer>
					</div>
				)}
				{shop.img && (
					<div className="img" style={{ backgroundImage: "url(" + shop.img + ")" }}></div>
				)}
			</div>
		</div>
	);
}

export default Shop;
