import { useContext } from "react";
import { AppContext } from "../App";
import { useNavigate } from "react-router-dom";
import Countdown from "./Countdown";

function Slide({ slide }) {
	const { track, getTranslatedString } = useContext(AppContext);
	const navigate = useNavigate();

	const handleSlideAction = (slide) => {
		if (slide.action == "store") {
			navigate("/store");
			track();
		}
		if (slide.action == "reservations") {
			navigate("/booking");
			track();
		}
		if (slide.action == "shops") {
			navigate("/shops");
			track();
		}
		if (slide.action == "page" && slide.page_id) {
			navigate("/cms/" + slide.page_id);
			track();
		}
		if (slide.action == "url" && slide.url) {
			if (slide.settings.target_same == 1) window.location.href = slide.url;
			else window.open(slide.url);
		}
		if (slide.action == "news") {
			navigate("/news");
			track();
		}
	};

	return (
		<div
			style={{
				backgroundColor: slide.settings?.bg_color ? slide.settings.bg_color : "none",
				backgroundImage:
					slide.content_alignment == "center" ? "url(" + slide.img + ")" : "none",
				minHeight: slide.settings?.height
					? "calc(" + slide.settings.height + "dvh - var(--header-h))"
					: "auto",
			}}
			className={"slide " + slide.content_alignment + " slide-" + slide.id}
		>
			{slide.content_alignment !== "center" && slide.img && (
				<div
					className="img"
					style={{
						backgroundImage: "url(" + slide.img + ")",
					}}
				></div>
			)}
			{(slide.title || slide.text || slide.url) && (
				<div
					className="content"
					style={{
						backgroundColor: slide.settings?.bg_color
							? slide.settings.bg_color
							: "transparent",
						color: slide.settings?.text_color ? slide.settings.text_color : "none",
					}}
				>
					{slide.title && (
						<div
							className="title"
							dangerouslySetInnerHTML={{
								__html: getTranslatedString(slide, "title"),
							}}
						/>
					)}
					{slide.text && (
						<div
							className="text"
							dangerouslySetInnerHTML={{ __html: getTranslatedString(slide, "text") }}
						/>
					)}
					{slide.countdown && (
						<Countdown timeTillDate={slide.countdown} timeFormat="YYYY-MM-DD h:mm:ss" />
					)}
					{slide.action && (
						<button
							style={{
								backgroundColor: slide.settings?.btn_bg_color
									? slide.settings.btn_bg_color
									: "none",
								color: slide.settings?.btn_text_color
									? slide.settings.btn_text_color
									: "none",
							}}
							onClick={() => handleSlideAction(slide)}
						>
							{getTranslatedString(slide, "button_text")}
						</button>
					)}
				</div>
			)}
		</div>
	);
}

export default Slide;
