import { useContext, useEffect, useRef, useState } from "react";
import GooglePlacesAutocomplete, { geocodeByPlaceId } from "react-google-places-autocomplete";
import { AppContext } from "../App";
import Label from "./Label";

function GoogleAddress({ address, onCange, emptyOnSubmit = false }) {
	const { settings, lang } = useContext(AppContext);
	const [googleAddress, setGoogleAddress] = useState(null);
	const [street, setStreet] = useState("");
	const [number, setNumber] = useState("");
	const [zipcode, setZipcode] = useState("");
	const [city, setCity] = useState("");
	const [province, setProvince] = useState("");
	const [doorphone, setDoorphone] = useState("");
	const [coords, setCoords] = useState("");
	const [err, setErr] = useState("");
	const [placeholder, setPlaceholder] = useState("");
	const srcInput = useRef();

	useEffect(() => {
		console.log(address);
		if (address) {
			setStreet(address.address ? address.address : "");
			setNumber(address.number ? address.number : "");
			setZipcode(address.zipcode ? address.zipcode : "");
			setCity(address.city ? address.city : "");
			setProvince(address.province ? address.province : "");
			setDoorphone(address.doorphone ? address.doorphone : "");
			setCoords(address.coord ? address.coord : "");
			setPlaceholder(
				address.address +
					" " +
					address.number +
					", " +
					address.city +
					", " +
					address.province
			);
		}
	}, [address]);

	useEffect(() => {
		console.log(googleAddress);
		if (googleAddress) fillInAddress();
	}, [googleAddress]);

	function fillInAddress() {
		console.log("fillInAddress");
		geocodeByPlaceId(googleAddress.value.place_id)
			.then((place) => {
				console.log(place);
				const addressComponents = place[0].address_components;
				addressComponents.forEach(function (component) {
					if (component.types[0] == "route") setStreet(component.short_name);
					if (component.types[0] == "street_number") setNumber(component.short_name);
					if (component.types[0] == "locality") setCity(component.long_name);
					if (component.types[0] == "administrative_area_level_3")
						setCity(component.long_name);
					if (component.types[0] == "postal_code") setZipcode(component.short_name);
					if (component.types[0] == "administrative_area_level_2")
						setProvince(component.short_name);
				});
				setCoords(
					place[0].geometry.location.lat() + "," + place[0].geometry.location.lng()
				);
			})
			.catch((error) => console.error(error));
	}

	const saveAddress = async () => {
		if (!check()) return;
		onCange({
			address: street,
			number: number,
			zipcode: zipcode,
			city: city,
			province: province,
			doorphone: doorphone,
			coord: coords,
		});
		if (emptyOnSubmit) {
			srcInput.current.clearValue();
			setGoogleAddress(null);
			setStreet("");
			setNumber("");
			setZipcode("");
			setCity("");
			setProvince("");
			setDoorphone("");
			setCoords("");
		}
	};

	function check() {
		let e = "";
		if (settings.customers?.doorphone_required == 1 && !doorphone)
			e += "Citofono è obbligatorio\n";
		if (!street) e += "Indirizzo è obbligatorio\n";
		if (!number) e += "Numero civico è obbligatorio\n";
		if (!zipcode) e += "Cap è obbligatorio\n";
		if (!city) e += "Città è obbligatorio\n";
		if (!province) e += "Provincia è obbligatorio\n";
		if (!coords) e += "Errore Coordinate\n";
		setErr(e);
		return e == "";
	}

	return (
		<>
			{err && <div className="error-box">{err}</div>}
			<GooglePlacesAutocomplete
				apiKey={settings.google.api_key}
				apiOptions={{ language: lang }}
				autocompletionRequest={{
					componentRestrictions: {
						country: ["it", "ch"],
					},
					types: ["address"],
				}}
				selectProps={{
					defaultInputValue: address
						? address.address +
						  " " +
						  address.number +
						  ", " +
						  address.city +
						  ", " +
						  address.province
						: "",
					value: googleAddress,
					onChange: setGoogleAddress,
					placeholder: placeholder,
					ref: srcInput,
				}}
			/>
			<div className="input-group">
				<label>
					<Label number={34} />:
				</label>
				<input
					type="text"
					value={doorphone}
					onChange={(e) => setDoorphone(e.target.value)}
				/>
			</div>
			<button className="button button-primary" onClick={saveAddress}>
				<Label number={78} />
			</button>
		</>
	);
}

export default GoogleAddress;
