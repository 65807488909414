export default function Cereali({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m303.4,125.65c-.4,27.14-11.06,52.71-32.21,73.68-14.09,13.98-28.22,13.82-42.34.19-43.24-41.74-42.64-113.65,1.27-153.94,13.53-12.41,26.24-12.73,39.64.04,21.99,20.96,33.43,46.76,33.64,80.03Z"
				style={style}
			/>
			<path
				d="m135.24,330.44c53.24,1.7,100.59,49.92,101.25,103.1.23,18.95-10.73,30.18-29.59,30.3-53.59.36-104.09-50.23-103.84-104.01.09-19.36,11.79-30.04,32.18-29.39Z"
				style={style}
			/>
			<path
				d="m236.4,286.86c-.14,19.27-10.86,29.88-30.04,29.76-53.55-.35-102.66-49.24-103.26-102.8-.22-19.28,10.48-30.44,29.3-30.6,53.78-.44,104.39,50,104,103.64Z"
				style={style}
			/>
			<path
				d="m293.5,316.62c-19,.12-29.94-10.83-29.89-29.92.16-53.57,48.54-102.37,102.57-103.47,19.75-.4,31.04,10.92,30.74,30.84-.79,53.7-49.72,102.2-103.43,102.55Z"
				style={style}
			/>
			<path
				d="m295.61,463.75c-21.64.61-33.11-11.17-31.91-32.77,2.9-52.53,46.44-96.83,98.68-100.41,23.62-1.62,35.67,10.1,34.48,33.52-2.65,52.11-49.48,98.21-101.25,99.66Z"
				style={style}
			/>
		</svg>
	);
}
