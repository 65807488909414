import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import PopUp from "../components/PopUp";

function PagePopup() {
	const { pages, pagePopup, setPagePopup, getTranslatedString } = useContext(AppContext);
	const [page, setPage] = useState(false);

	useEffect(() => {
		const p = pages.find((page) => page.id == pagePopup);
		setPage(p);
	}, []);

	if (!page) return null;

	return (
		<PopUp>
			<div className="header">
				{getTranslatedString(page, "title")}
				<button onClick={() => setPagePopup(false)}>
					<i className="fas fa-times"></i>
				</button>
			</div>
			<div className="content">
				<div dangerouslySetInnerHTML={{ __html: getTranslatedString(page, "text") }} />
			</div>
		</PopUp>
	);
}

export default PagePopup;
