import { Link, useNavigate } from "react-router-dom";
import Label from "./Label";
import { useContext, useEffect } from "react";
import { AppContext } from "../App";

function UserboardMenu({ selected }) {
	const { settings, user, setUser, track, setConfirm } = useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		const scrollContainer = document.getElementById("userboard-menu");
		console.log(scrollContainer);
		scrollContainer.scrollTo(0, 0);
	}, []);

	const logout = () => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: () => {
				setUser(false);
				localStorage.removeItem("website-user");
				navigate("/login");
				track();
			},
		});
	};

	return (
		<div id="userboard-menu" className="menu">
			<Link to="/userboard" className={selected == "userboard" ? "selected" : ""}>
				<Label number={42} />
			</Link>
			{settings.orders?.active == 1 && (
				<Link to="/orders" className={selected == "orders" ? "selected" : ""}>
					<Label number={52} />
				</Link>
			)}
			{settings.reservations?.active == 1 && (
				<Link to="/bookings" className={selected == "bookings" ? "selected" : ""}>
					<Label number={53} />
				</Link>
			)}
			{settings.fidelity?.active == 1 && (
				<Link
					to="/points_transactions"
					className={selected == "points_transactions" ? "selected" : ""}
				>
					<Label number={119} />
				</Link>
			)}
			{settings.credit?.active == 1 && (
				<Link
					to="/credits_transactions"
					className={selected == "credits_transactions" ? "selected" : ""}
				>
					<Label number={113} />
				</Link>
			)}
			{user && (
				<button onClick={logout} className="button button-icon">
					<Label number={37} className="button" />
				</button>
			)}
		</div>
	);
}

export default UserboardMenu;
