export default function Molluschi({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m465.57,191.44c-46.55-81.91-118.44-124.36-211.9-125.63-94.09-1.28-167.82,39.1-216.54,120.67-25.23,42.25-17.52,76.83,20.66,107.44,29.97,24.02,59.59,48.49,89.96,71.99,9.49,7.34,11.8,13.48,7.07,25.16-10.39,25.69,2.05,42.61,30.24,43,21.27.3,42.55.06,63.82.06,21.27,0,42.55.12,63.82-.02,30.65-.21,42.86-16.39,31.92-44.33-4.45-11.36-1.21-16.91,7.31-23.6,31.61-24.8,62.86-50.06,94-75.46,33.29-27.16,40.94-61.78,19.63-99.28Zm-266.38,126.56c-4.69,1.47-12.01.5-15.82-2.46-6.84-5.31-11.97-12.86-17.73-19.55-21.13-24.53-42.27-49.07-63.31-73.67-6.8-7.95-13.49-16.83-2.55-25.78,10.03-8.2,17.41-.62,23.79,6.81,25.33,29.46,50.62,58.96,75.77,88.57,3.33,3.92,5.77,8.6,9.5,14.27-3.45,4.49-5.79,10.6-9.66,11.81Zm64.97-32.08c0,10.37-2.09,20.09-14.67,19.72-12.63-.38-13.72-10.58-13.68-20.67.09-26.56.03-53.13.03-79.69h-.06c0-25.38.08-50.77-.04-76.15-.05-10.2,1.38-20.27,13.84-20.6,12.48-.34,14.62,9.38,14.61,19.79-.08,52.54-.06,105.08-.02,157.61Zm133.17-63.39c-24.75,29.18-49.68,58.22-74.63,87.23-6.83,7.94-14.65,15.88-25.11,6.39-9.51-8.62-3.32-17.01,3.15-24.54,25.68-29.94,51.01-60.18,77.36-89.51,4.12-4.59,12.81-6.53,19.48-6.73,3.08-.09,6.36,6.97,10.17,11.53-4.02,6.09-6.73,11.28-10.42,15.63Z"
				style={style}
			/>
		</svg>
	);
}
