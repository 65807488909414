import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../App";
import Slide from "../components/Slide";

function Cms() {
	const { slides, pages, getTranslatedString, lang } = useContext(AppContext);
	const [page, setPage] = useState(false);
	const [pageSlides, setPageSlides] = useState([]);
	let params = useParams();

	useEffect(() => {
		const scrollContainer = document.getElementById("main");
		scrollContainer.scrollTo(0, 0);
	}, [page, lang]);

	useEffect(() => {
		const p = pages.find((page) => page.id == params.id);
		//console.log(p);
		setPage(p);
		const filteredSlides = slides.filter((s) => {
			return p.slides.find((ps) => ps.id == s.id);
		});
		setPageSlides(filteredSlides);
	}, [params]);

	return (
		<div className="page">
			<h1 id="page-header">{getTranslatedString(page, "title")}</h1>
			<div className="slides">
				{pageSlides.map((slide, i) => (
					<Slide key={i} slide={slide} />
				))}
			</div>
			<div className="container">
				{page && (
					<>
						{page.img && (
							<div className="img">
								<img src={page.img} />
							</div>
						)}
						<div
							dangerouslySetInnerHTML={{ __html: getTranslatedString(page, "text") }}
						/>
					</>
				)}
			</div>
		</div>
	);
}

export default Cms;
