import { useContext } from "react";
import { AppContext } from "../App";
import Label from "./Label";

export default function Maintenance() {
	const { settings } = useContext(AppContext);
	return (
		<div className="maintenance">
			<div className="content">
				<div className="logo">
					<img src={settings.website.logo} />
				</div>
				<div className="text">
					<Label number={210} />
				</div>
			</div>
		</div>
	);
}
