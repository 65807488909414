export default function Soia({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m417.41,386.63c-.59-.18-12.18-3.77-30.6-10.81,1.94-12.07-1.3-24.27-8.48-33.79,45.52-16.25,89.8-85.62,89.8-132.92,0-2.03-.91-3.96-2.48-5.26-1.57-1.3-3.65-1.83-5.63-1.44-2.07.39-205.77,37.53-357.63-109.76,4.13-4.04,8.43-8.17,12.9-12.47,7.51-7.22,15.42-14.82,23.64-23.04,2.66-2.66,2.66-6.98,0-9.64-2.66-2.66-6.98-2.66-9.64,0-8.15,8.15-16,15.69-23.45,22.85C62.9,111.62,31.87,141.45,31.87,202.28c0,24.12,10.51,46.12,27.53,61.22,0,.19-.03.38-.02.57,3.47,52.77,44.58,94.94,96.01,101.02,16.34,41.28,56.97,68.95,101.43,68.95,1.5,0,2.99-.03,4.48-.09,18.63,13.43,40.59,20.54,63.69,20.54,40.55,0,77.52-22.29,96.48-58.16.95-1.8,1.05-3.93.27-5.81-.79-1.88-2.37-3.31-4.31-3.9ZM146.96,215.65c-15.74-20.51-28.51-42.9-36.75-67.19.11-.07.24-.11.35-.18,14.11-9.15,33.11-6.47,44.18,6.24l4.7,5.38c6.08,6.97,9.02,15.89,8.29,25.11-.74,9.22-5.05,17.55-12.16,23.47l-8.61,7.17Zm76.62,72.01c-.15.28-.29.55-.4.84-24.6-18.17-48-38.91-67.67-62.24,0,0,0,0,0,0l8.78-7.31c2.06-1.72,3.94-3.59,5.66-5.58.36-.1.72-.2,1.07-.36,15.82-7.22,34.55-3.6,46.57,8.99l6.9,7.23c14.8,15.52,15.05,40.06.55,55.87-.7.76-1.17,1.64-1.46,2.56Zm77.61,49.38c-21.91-11.57-44.97-25.19-67.37-40.88.18-.15.38-.28.54-.44l7.47-7.47c12.44-12.44,32.71-13.27,46.13-1.92l6.88,5.82c13.27,11.23,15.79,30.71,6.35,44.9Zm72.47,33.6c-17.01-6.87-37.83-15.96-60.31-27.32.51-.83,1.01-1.66,1.46-2.52.27-.16.54-.31.79-.51,9.61-7.69,22.83-8.87,33.66-3.04l8.32,4.47c10.69,5.74,16.78,17.2,16.07,28.91Z"
				style={style}
			/>
		</svg>
	);
}
