import * as React from "react";

function CheckIcon(props) {
	return (
		 <svg
      fill="currentColor"
      viewBox="0 0 16 16"
      height="18px"
      width="18px"
      {...props}
    >
      <path d="M16 8A8 8 0 110 8a8 8 0 0116 0zm-3.97-3.03a.75.75 0 00-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 00-1.06 1.06L6.97 11.03a.75.75 0 001.079-.02l3.992-4.99a.75.75 0 00-.01-1.05z" />
    </svg>
	);
}

export default CheckIcon;
