import { useContext, useState } from "react";
import { AppContext } from "../App";
import { getCookie, setCookie } from "./functions";
import Label from "./Label";

function Cookies() {
	const {
		lang,
		settings,
		setPagePopup,
		c1,
		setC1,
		cookieSettingsPopupVisible,
		setCookieSettingsPopupVisible,
	} = useContext(AppContext);

	return (
		<>
			{!c1 && (
				<div id="cookies">
					<div className="content">
						<div className="title">Cookie</div>
						{lang == "it" ? (
							<div className="text">
								Cliccando su “Accetta tutti i cookie”, l'utente accetta di
								memorizzare i cookie sul dispositivo per migliorare la navigazione
								del sito, analizzare l'utilizzo del sito e assistere nelle nostre
								attività di marketing.{" "}
								<a
									href="#"
									onClick={() => setPagePopup(settings.orders.privacy_page_id)}
								>
									Scopri di più
								</a>
							</div>
						) : (
							<div className="text">
								This site or the third-party tools used by it make use of cookies
								necessary for the functioning and useful for the purposes
								illustrated in the{" "}
								<a
									href="#"
									onClick={() => setPagePopup(settings.orders.privacy_page_id)}
								>
									privacy policy
								</a>
								. If you want to know more or deny consent to all or ad some
								cookies, see the{" "}
								<a
									href="#"
									onClick={() => setPagePopup(settings.orders.privacy_page_id)}
								>
									privacy policy
								</a>
								. By closing this banner, by scrolling this page, clicking on a link
								or continuing to browse otherwise, you consent to the use of
								cookies.
							</div>
						)}
					</div>
					<div className="button-container">
						<button
							className="button button-secondary"
							onClick={() => setCookieSettingsPopupVisible(true)}
						>
							<Label number={195} />
						</button>
						<button
							className="button button-primary"
							onClick={() => {
								setCookie("website-cookies-a", 1);
								setCookie("website-cookies-b", 1);
								setCookie("website-cookies-c", 1);
								setCookie("website-cookies-d", 1);
								setC1(true);
							}}
						>
							Accetta tutti i cookie
						</button>
					</div>
				</div>
			)}
			{cookieSettingsPopupVisible && <SettingsPopup />}
		</>
	);
}

function SettingsPopup() {
	const { setC1, setCookieSettingsPopupVisible } = useContext(AppContext);
	const [c2, setC2] = useState(getCookie("website-cookies-b"));
	const [c3, setC3] = useState(getCookie("website-cookies-c"));
	const [c4, setC4] = useState(getCookie("website-cookies-d"));

	return (
		<div className="cookie-settings">
			<div className="content">
				<div className="title">Cookie</div>
				<div className="text">
					Quando si visita qualsiasi sito Web, questo può memorizzare o recuperare
					informazioni sul tuo browser, in gran parte sotto forma di cookie. Queste
					informazioni potrebbero essere su di te, le tue preferenze o il tuo dispositivo
					e sono utilizzate in gran parte per far funzionare il sito secondo le tue
					aspettative. Le informazioni di solito non ti identificano direttamente, ma
					possono fornire un'esperienza Web più personalizzata. Poiché rispettiamo il tuo
					diritto alla privacy, è possibile scegliere di non consentire alcuni tipi di
					cookie. Clicca sulle intestazioni delle diverse categorie per saperne di più e
					modificare le impostazioni predefinite. Tuttavia, il bloccaggio di alcuni tipi
					di cookie può avere impatto sulla tua esperienza del sito e dei servizi che
					siamo in grado di offrire.
				</div>
				<div className="checkbox-line">
					<div className="text">Cookie strettamente necessari</div>
					<div className="checkbox">Sempre attivi</div>
				</div>
				<div className="checkbox-line">
					<div className="text">Cookie di prestazione</div>
					<div className="checkbox">
						<input
							type="checkbox"
							checked={c2}
							onChange={(e) => {
								if (e.target.checked) setC2(true);
								else setC2(false);
							}}
						/>
					</div>
				</div>
				<div className="checkbox-line">
					<div className="text">Cookie per funzionalità</div>
					<div className="checkbox">
						<input
							type="checkbox"
							checked={c3}
							onChange={(e) => {
								if (e.target.checked) setC3(true);
								else setC3(false);
							}}
						/>
					</div>
				</div>
				<div className="checkbox-line">
					<div className="text">Cookie per pubblicità mirata</div>
					<div className="checkbox">
						<input
							type="checkbox"
							checked={c4}
							onChange={(e) => {
								if (e.target.checked) setC4(true);
								else setC4(false);
							}}
						/>
					</div>
				</div>
				<div className="button-container">
					<button
						className="button button-primary"
						onClick={() => {
							setCookie("website-cookies-a", 1);
							if (c2) setCookie("website-cookies-b", 1);
							else setCookie("website-cookies-b", 0, -1);
							if (c3) setCookie("website-cookies-c", 1);
							else setCookie("website-cookies-c", 0, -1);
							if (c4) setCookie("website-cookies-d", 1);
							else setCookie("website-cookies-d", 0, -1);
							setCookieSettingsPopupVisible(false);
							setC1(true);
						}}
					>
						Conferma le mie scelte
					</button>
					<button
						className="button button-econdary"
						onClick={() => setCookieSettingsPopupVisible(false)}
					>
						<Label number={129} />
					</button>
				</div>
			</div>
		</div>
	);
}

export default Cookies;
