import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import FeaturedProducts from "../components/FeaturedProducts";
import Slide from "../components/Slide";

function Home() {
	const { lang, slides } = useContext(AppContext);

	useEffect(() => {
		const scrollContainer = document.getElementById("main");
		scrollContainer.scrollTo(0, 0);
	}, [lang]);

	return (
		<div className="page home">
			<div className="slides">
				{slides &&
					slides.map(
						(slide, i) => slide.slide_header == 1 && <Slide key={i} slide={slide} />
					)}
			</div>
			<div className="container">
				<FeaturedProducts />
			</div>
			<div className="slides">
				{slides &&
					slides.map(
						(slide, i) => slide.slide_footer == 1 && <Slide key={i} slide={slide} />
					)}
			</div>
		</div>
	);
}

export default Home;
