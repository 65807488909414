import { useNavigate } from "react-router-dom";
import Label from "../components/Label";
import api from "../components/api";
import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import Form from "../components/form";
import { setCookie } from "../components/functions";
import jwt_decode from "jwt-decode";

function CheckPasswordCode() {
	const { setLoading, setConfirm, tempUser, lang, setUser, track } = useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (!tempUser) {
			navigate("/check_email");
			track();
		}
	}, [tempUser]);

	const handleCheckCode = async (request) => {
		setLoading(true);
		request.lang = lang;
		const loginRes = await api.post(
			"/customers/check_new_password_code/" + tempUser.id + "/",
			request
		);
		setLoading(false);
		if (loginRes.success == 0)
			setConfirm({
				title: "Errore",
				message: loginRes.error,
			});
		else {
			api.setToken(loginRes.token);
			const decoded = jwt_decode(loginRes.token);
			localStorage.setItem("website-user", loginRes.token);
			const response = await api.getProtected("/customers/get/" + decoded.user_id + "/");
			if (response.success == 0) alert(response.error);
			else {
				setUser(response.data);
				navigate("/new_password");
				track();
			}
		}
	};

	return (
		<div className="page">
			<div className="login">
				<div className="content">
					<h1>
						<Label number={126} />
					</h1>
					<p>
						<Label number={127} />
					</p>
					<Form
						onSubmit={handleCheckCode}
						fields={[
							{
								type: "number",
								name: "reset_password_code",
								label: <Label number={128} />,
								value: "",
								required: true,
							},
						]}
						submitButtonLabel={<Label number={41} />}
					/>
				</div>
			</div>
		</div>
	);
}

export default CheckPasswordCode;
