import { useContext, useState } from "react";
import { AppContext } from "../App";
import Nexi from "./paymentsButtons/Nexi";
//import Paypal from "./paymentsButtons/Paypal";
import Stripe from "./paymentsButtons/Stripe";
import Satispay from "./paymentsButtons/Satispay";
import Paypal from "./paymentsButtons/Paypal";
import Vivawallet from "./paymentsButtons/Vivawallet";

function PaymentPopup({ onPayment, onCancel }) {
	const { cart } = useContext(AppContext);
	const [paymentPopupVisible, setPaymentPopupVisible] = useState(false);

	return (
		<div className="payment-popup">
			<div className="content">
				{cart.payment_method_id == 3 && (
					<div className="paypal">
						<Paypal onPayment={onPayment} onCancel={onCancel} />
					</div>
				)}
				{cart.payment_method_id == 8 && <Satispay onCancel={onCancel} />}
				{cart.payment_method_id == 9 && (
					<Stripe onPayment={onPayment} onCancel={onCancel} />
				)}
				{cart.payment_method_id == 11 && <Nexi onCancel={onCancel} />}
				{cart.payment_method_id == 12 && <Vivawallet onCancel={onCancel} />}
				{paymentPopupVisible && (
					<PaymentPopup
						onPayment={onPayment}
						onCancel={() => setPaymentPopupVisible(false)}
					/>
				)}
			</div>
		</div>
	);
}

export default PaymentPopup;
