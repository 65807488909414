export default function Pesce({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m445.43,250.35c9.9-21.94,23.18-43.85,29.69-67.62,9.3-34-9.33-53.27-43.16-44.25-24.86,6.63-47.77,20.6-72.41,31.68-20.56-10.66-44.59-24.33-69.72-35.55-10.1-4.51-12.73-8.85-12.1-19.38,1.54-26.1-11.93-37.96-38.31-35.11-32.18,3.48-57.9,18.61-78.8,43.15-5.45,6.39-12.79,12.11-20.42,15.54-45.51,20.41-81.17,52.25-110.21,92.22-8.13,11.2-10.89,23.4-1.81,34.33,19.46,23.44,40.14,45.86,60.74,69.16,29.43-20.68,43.77-45.22,46.95-76.32,1.12-10.99,1.51-23.56,16.66-21.51,14.67,1.99,12.19,14.94,11.67,25.53-.11,2.35-.74,4.66-1.09,7-4.66,31.63-20.41,57.31-41.54,76.5,20.77,17.63,39.32,36.25,60.73,50.59,15.72,10.53,34.59,18.21,53.12,22.28,29.93,6.58,42.88-6.37,42.72-36.91-.02-4.5,1.64-11.5,4.73-13.14,26.89-14.15,54.28-27.34,77.1-38.63,24.68,11.09,47.07,24.8,71.39,31.2,34.94,9.2,53.34-10.07,43.44-45.26-6.66-23.69-20.12-45.47-29.35-65.53Zm-350.76-.45c0,6.7-5.43,12.13-12.13,12.13s-12.13-5.43-12.13-12.13,5.43-12.13,12.13-12.13,12.13,5.43,12.13,12.13Z"
				style={style}
			/>
		</svg>
	);
}
