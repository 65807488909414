function NotFound() {
	return (
		<div className="page">
			<div className="container">
				<h1>Page not found</h1>
			</div>
		</div>
	);
}

export default NotFound;
