export default function Uova({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m342.07,102.17c-51.97-73.11-126.3-76.18-180.52-5.16-47.77,62.57-69.97,135.31-63.46,213.55,11.96,143.7,149.62,167.4,227.13,125.1,57.24-31.24,75.83-84,77.8-147.17-3.53-66.41-20.95-130.04-60.95-186.32Zm-92.41,275.08c-42.24.03-77.16-35.43-76.43-77.61.73-42.32,34.69-75.42,77.02-75.09,41.81.33,75.93,34.86,75.69,76.61-.24,41.87-34.51,76.05-76.27,76.08Z"
				style={style}
			/>
		</svg>
	);
}
