export default function Lupini({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m157.84,453.27c-35.33,6.23-58.34-11.65-75.91-43.5-28.45-51.55-30.24-38.77.58-90.23,25.47-42.52,73.69-58.77,120.11-39.58,9.73,4.02,19.18,11.89,25.45,20.43,11.55,15.73,21.98,32.65,30.13,50.34,3.61,7.84,3.51,20.16-.13,28.01-8.2,17.67-19.23,34.11-29.93,50.52-9.87,15.14-24.53,23.08-42.65,23.94-8.46.4-16.95.07-27.65.07Zm-59.8-87.39c1.96,2.05,6.82,10.53,10.32,10.01,5.44-.81,13.45-7.18,14.28-12.2,3.79-22.74,16.44-34.09,38.62-38.02,4.78-.85,8.33-8.63,12.45-13.22-4.96-3.77-9.75-10.53-14.9-10.82-29.9-1.66-60.8,29.42-60.78,64.25Z"
				style={style}
			/>
			<path
				d="m163.75,46.93c36.18-6.86,59.55,11.45,77.4,43.81,28.28,51.27,29.71,38.67-.22,88.89-25.98,43.59-71.43,59.34-119.73,40.96-8.55-3.26-17.79-8.93-22.91-16.17-13.12-18.54-25.19-38.04-35.31-58.34-3.3-6.61-1.67-18.04,1.85-25.23,8.36-17.06,18.72-33.23,29.16-49.18,16.19-24.74,41.21-27.23,69.76-24.73Zm-65.68,89.88c1.99,2.05,6.12,9.73,10.6,9.94,4.63.22,13.15-6.31,13.82-10.8,3.44-23.29,15.66-35.69,38.91-39.39,4.69-.75,8.22-8.75,12.29-13.4-5-3.69-9.86-10.32-15.04-10.58-29.9-1.54-60.84,29.63-60.58,64.23Z"
				style={style}
			/>
			<path
				d="m344.27,160.98c33.63-6.02,57.47,12.12,75.61,45.14,27.37,49.84,26.22,37.18.33,87.82-26.83,52.46-69.42,50.04-121.7,40.34-10.26-1.9-20.26-13.23-27.3-22.54-10.79-14.27-18.8-30.63-28.21-45.97-6.49-10.58-6.41-20.91,0-31.42,5.79-9.49,11.42-19.08,17.19-28.59,24.8-40.87,31.75-44.79,84.09-44.79Zm-67.97,90.59c1.99,2.01,6.19,9.65,10.67,9.82,4.61.18,13-6.39,13.69-10.92,3.53-23.25,15.67-35.73,39.01-39.33,4.64-.71,8.15-8.81,12.18-13.51-5.02-3.66-9.88-10.22-15.07-10.49-29.91-1.56-60.88,29.77-60.48,64.43Z"
				style={style}
			/>
		</svg>
	);
}
