import { useContext } from "react";
import { AppContext } from "../App";
import "./../css/Brands.css";
import Label from "../components/Label";

function Brands() {
	const { mainSettings, setBrand } = useContext(AppContext);

	return (
		<div className="brands">
			<div className="content">
				<h1 className="title">
					<Label number={186} />
				</h1>
				<div className="list">
					<button onClick={() => setBrand("main")}>
						<img src={mainSettings.global.logo} alt={mainSettings.global.name} />
					</button>
					{mainSettings.mandant?.childs?.map((b, i) => (
						<button key={i} onClick={() => setBrand(b)}>
							<img src={b.img} alt={b.name} />
						</button>
					))}
				</div>
			</div>
		</div>
	);
}

export default Brands;
