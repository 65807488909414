import { useContext, useEffect, useState } from "react";
import { TileLayer, Tooltip, Marker, useMap } from "react-leaflet";

function ShopsMap({ shops }) {
	const map = useMap();

	useEffect(() => {
		if (shops.length > 1) {
			let coords = [];
			for (const shop of shops) {
				coords.push(shop.coord.split(","));
			}
			map.flyToBounds(coords);
		} else if (shops.length == 1) map.flyTo(shops[0].coord.split(","), map.getZoom());
	}, [shops]);

	return (
		<>
			<TileLayer
				attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
				url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
			/>
			{shops.map((shop, i) => (
				<Marker key={i} position={shop.coord.split(",")}></Marker>
			))}
		</>
	);
}

export default ShopsMap;
