import { useContext } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { AppContext } from "../../App";
import { useNavigate } from "react-router-dom";

function Paypal({ onPayment, onCancel }) {
	const { settings, cart, setLastOrderId, user, setLastOrderTotal } = useContext(AppContext);
	const navigate = useNavigate();

	async function createOrder() {
		console.log("createOrder");
		const response = await fetch(
			getBaseUrl() +
				"/plugins/paypal2/create_order.php?mandant_id=" +
				settings.mandant.id +
				"&shop_id=" +
				cart.shop_id +
				"&amount=" +
				cart.total +
				"&origin=website",
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		const data = await response.json();
		console.log(data);
		return data.data.id;
	}

	async function captureOrder(data) {
		console.log("captureOrder");
		const response = await fetch(
			getBaseUrl() +
				"/plugins/paypal2/capture_order.php?mandant_id=" +
				settings.mandant.id +
				"&shop_id=" +
				cart.shop_id +
				"&order_id=" +
				data.orderID +
				"&customer_id=" +
				cart.customer_id +
				"&origin=website&logged=" +
				(user ? 1 : 0),
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
			}
		);
		const orderData = await response.json();
		console.log(orderData);
		onCancel();
		setLastOrderId(orderData.id);
		setLastOrderTotal(cart.total);
		navigate("/order_confirmed");
	}

	function getBaseUrl() {
		let api_base_url;
		if (
			window.location.hostname === "localhost" ||
			window.location.hostname.includes(".localhost")
		)
			api_base_url = "http://api.sdm.localhost";
		else if (window.location.hostname.includes(".dev."))
			api_base_url = "https://api.dev.yellgo.cloud";
		else if (window.location.hostname.includes(".demo."))
			api_base_url = "https://api.demo.yellgo.cloud";
		else api_base_url = "https://api.yellgo.cloud";
		return api_base_url;
	}

	function get_currency(currency) {
		if (currency == "CHF") return "CHF";
		if (currency == "DKK") return "DKK";
		return "EUR";
	}

	if (
		cart.shop.payment_methods[3].environment == "sandbox" &&
		!cart.shop.payment_methods[3].sandbox_client_id
	)
		return null;

	if (
		cart.shop.payment_methods[3].environment != "sandbox" &&
		!cart.shop.payment_methods[3].production_client_id
	)
		return null;

	return (
		<>
			<PayPalScriptProvider
				options={{
					clientId:
						cart.shop.payment_methods[3].environment == "sandbox"
							? cart.shop.payment_methods[3].sandbox_client_id
							: cart.shop.payment_methods[3].production_client_id,
					currency: get_currency(cart.shop.currency),
				}}
			>
				<PayPalButtons
					style={{ layout: "horizontal" }}
					createOrder={createOrder}
					onApprove={captureOrder}
				/>
			</PayPalScriptProvider>
			<button className="button" type="button" onClick={onCancel}>
				Annulla
			</button>
		</>
	);
}

export default Paypal;
