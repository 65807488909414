import Label from "../Label";

function CategoriesMenu2Button() {
	return (
		<button
			id="categories-menu-2-button"
			onClick={() =>
				(document.getElementsByClassName("categories-menu-2")[0].style.display = "flex")
			}
		>
			<Label number={172} />
		</button>
	);
}

export default CategoriesMenu2Button;
