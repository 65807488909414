import { useContext, useEffect } from "react";
import Label from "../components/Label";
import { AppContext } from "../App";
import moment from "moment/moment";
import { Link } from "react-router-dom";

function NewsList() {
	const { news } = useContext(AppContext);

	useEffect(() => {
		const scrollContainer = document.getElementById("main");
		scrollContainer.scrollTo(0, 0);
	}, []);

	return (
		<div className="page page-news-list">
			<h1 id="page-header">
				<Label number={177} />
			</h1>
			<div className="container news">
				{news &&
					news.map((el, i) =>
						el.external_url ? (
							<a href={el.external_url} target="_blank">
								<Content el={el} />
							</a>
						) : (
							<Link key={i} to={"/news/" + el.id} state={el}>
								<Content el={el} />
							</Link>
						)
					)}
			</div>
		</div>
	);
}

function Content({ el }) {
	const { getTranslatedString } = useContext(AppContext);

	return (
		<>
			{el.img && (
				<div className="img">
					<img src={el.img} alt="news image" />
				</div>
			)}
			<div className="content">
				<div className="date">
					{moment(el.date, "YYYY-MM-DD HH:mm:ss").format("DD/MM/YYYY")}
				</div>
				<div className="title">{getTranslatedString(el, "title")}</div>
				<div
					className="description"
					dangerouslySetInnerHTML={{
						__html: getTranslatedString(el, "text"),
					}}
				/>{" "}
				<Label number={178} />
			</div>
		</>
	);
}

export default NewsList;
