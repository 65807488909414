import moment from "moment/moment";

function TimeRange({ date, range }) {
	const dateObj = moment(date, "YYYY-MM-DD HH:mm:ss");

	if (!date || !range) return null;

	return <>{dateObj.format("HH:mm") + "/" + dateObj.add(range, "minutes").format("HH:mm")}</>;
}

export default TimeRange;
