import { useNavigate, useLocation } from "react-router-dom";
import Label from "../components/Label";
import api from "../components/api";
import { useContext } from "react";
import { AppContext } from "../App";
import Form from "../components/form";

function BillingAddress() {
	const { setLoading, setConfirm, track, user, loadCart } = useContext(AppContext);
	const navigate = useNavigate();
	const location = useLocation();
	const address = location.state;

	const saveAddress = async (request) => {
		setLoading(true);
		let response;
		request.customer_id = user.id;
		if (address) {
			response = await api.postProtected(
				"/addresses_billing/edit/" + address.id + "/",
				request
			);
		} else {
			response = await api.postProtected("/addresses_billing/add/", request);
		}
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else {
			loadCart();
			navigate("/userboard");
			track();
		}
	};

	return (
		<div className="page">
			<div className="userboard">
				<div className="box">
					<h1>
						<Label number={57} />
					</h1>
					<Form
						onSubmit={saveAddress}
						fields={[
							{
								type: "text",
								name: "company",
								label: <Label number={58} />,
								value: address && address.company,
								required: true,
							},
							{
								type: "text",
								name: "sdi",
								label: <Label number={59} />,
								value: address && address.sdi,
								required: true,
							},
							{
								type: "text",
								name: "vat",
								label: <Label number={189} />,
								value: address && address.vat,
								required: true,
							},
							{
								type: "text",
								name: "address",
								label: <Label number={60} />,
								value: address && address.address,
								required: true,
							},
							{
								type: "text",
								name: "number",
								label: <Label number={61} />,
								value: address && address.number,
								required: true,
							},
							{
								type: "number",
								name: "zipcode",
								label: <Label number={62} />,
								value: address && address.zipcode,
								required: true,
							},
							{
								type: "text",
								name: "city",
								label: <Label number={63} />,
								value: address && address.city,
								required: true,
							},
							{
								type: "text",
								name: "province",
								label: <Label number={64} />,
								value: address && address.province,
								required: true,
							},
						]}
						submitButtonLabel={<Label number={43} />}
					/>
				</div>
			</div>
		</div>
	);
}

export default BillingAddress;
