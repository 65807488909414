import { useContext, useEffect, useState } from "react";
import Label from "../components/Label";
import PopUp from "../components/PopUp";
import { AppContext } from "../App";
import { currencyFormat, hasRemovableIngredients } from "../components/functions";
import Allergens from "../components/Allergens";
import PlusMinus from "../components/PlusMinus";
import ProductLabels from "../components/ProductLabels";

function ProductSheet({ product, setSelectedProduct }) {
	const { cart, getTranslatedString, handleAddProduct } = useContext(AppContext);
	const [selectedVariants, setSelectedVariants] = useState([]);
	const [selectedSubroducts, setSelectedSubroducts] = useState([]);
	const [productNotes, setProductNotes] = useState("");
	const [total, setTotal] = useState(product.price);

	useEffect(() => {
		setDefaultQty();
	}, []);

	useEffect(() => {
		updateTotal();
	}, [selectedVariants, selectedSubroducts]);

	const setDefaultQty = () => {
		const arr = [];
		for (const variants_category of product.variants_categories) {
			for (const variant of variants_category.variants2) {
				if (variant.is_default > 0)
					arr.push({
						id: variant.id,
						qty: 1,
						variant_category_id: variants_category.id,
						price: variant.price,
					});
			}
		}
		setSelectedVariants(arr);
	};

	const editVariant = (id, variant_category_id, qty, price) => {
		console.log("editVariant", id, variant_category_id, qty, price);
		const newArr = [...selectedVariants];
		const el = newArr.find(
			(el) => el.id == id && el.variant_category_id == variant_category_id
		);
		if (el) {
			if (qty > el.qty) {
				el.qty += 1;
				setSelectedVariants(newArr);
			} else if (el.qty > 1) {
				el.qty -= 1;
				setSelectedVariants(newArr);
			} else if (el.qty == 1) {
				const index = newArr.indexOf(el);
				if (index > -1) newArr.splice(index, 1);
				setSelectedVariants(newArr);
			}
		} else if (qty > 0)
			setSelectedVariants([
				...newArr,
				{
					id: id,
					qty: 1,
					variant_category_id: variant_category_id,
					price: price,
				},
			]);
	};

	const getVariantQty = (id, variant_category_id) => {
		const el = selectedVariants.find(
			(el) => el.id == id && el.variant_category_id == variant_category_id
		);
		if (el) return el.qty;
		else return 0;
	};

	const editSubproduct = (id, variant_category_id, qty, price) => {
		const newArr = [...selectedSubroducts];
		const el = newArr.find(
			(el) => el.id == id && el.variant_category_id == variant_category_id
		);
		if (el) {
			if (qty > el.qty) {
				el.qty += 1;
				setSelectedSubroducts(newArr);
			} else if (el.qty > 0) {
				el.qty -= 1;
				setSelectedSubroducts(newArr);
			}
		} else
			setSelectedSubroducts([
				...newArr,
				{
					id: id,
					qty: 1,
					variant_category_id: variant_category_id,
					price: price,
				},
			]);
	};

	const getSubproductQty = (id, variant_category_id) => {
		const el = selectedSubroducts.find(
			(el) => el.id == id && el.variant_category_id == variant_category_id
		);
		if (el) return el.qty;
		else return 0;
	};

	const getCategoryQty = (variant_category_id) => {
		let tot = 0;
		for (const variant of selectedVariants) {
			if (variant.variant_category_id == variant_category_id) tot += variant.qty;
		}
		for (const subroduct of selectedSubroducts) {
			if (subroduct.variant_category_id == variant_category_id) tot += subroduct.qty;
		}
		return tot;
	};

	const updateTotal = () => {
		let tot = parseFloat(product.price);
		for (const variant of selectedVariants) {
			tot += variant.qty * parseFloat(variant.price);
		}
		for (const subroduct of selectedSubroducts) {
			tot += subroduct.qty * parseFloat(subroduct.price);
		}
		setTotal(tot);
	};

	const handleAddProductndVariants = () => {
		let notes = "";
		for (const ingredient of product.ingredients) {
			const el = document.getElementById("ing-" + ingredient.id);
			if (el && !el.checked) notes += el.value + "<br>";
		}
		if (productNotes) notes += productNotes;
		console.log("notes", notes);

		for (const variant_category of product.variants_categories) {
			const el = document.getElementById("vc-" + variant_category.id);
			const errorEl = el.getElementsByClassName("error");
			if (errorEl) errorEl[0].style.display = "none";
			if (getCategoryQty(variant_category.id) < variant_category.min) {
				console.log("c");
				el.scrollIntoView({ behavior: "smooth" });
				if (errorEl) errorEl[0].style.display = "block";
				return;
			}
		}

		const el = document.getElementById("product-notes");
		if (el) {
			if (product.mandatory_notes == 1 && !productNotes) {
				el.style.border = "5px solid red";
				el.scrollIntoView({ behavior: "smooth" });
				return;
			} else {
				el.style.border = "0";
			}
		}

		handleAddProduct(
			product,
			product.discounted ? 1 : 0,
			selectedVariants,
			selectedSubroducts,
			notes
		);
	};

	return (
		<>
			<PopUp>
				<div className="header">
					<div></div>
					<button onClick={() => setSelectedProduct(false)}>
						<i className="fas fa-times"></i>
					</button>
				</div>
				<div className="content product-sheet">
					{product.img && <img src={product.img} className="product-img" />}
					<ProductLabels product={product} />
					<h1>{getTranslatedString(product, "name")}</h1>
					{getTranslatedString(product, "description") && (
						<div
							className="description smoot"
							dangerouslySetInnerHTML={{
								__html: getTranslatedString(product, "description"),
							}}
						/>
					)}
					{product.youtube && (
						<div className="video">
							<iframe
								width="100%"
								height="315"
								src={"https://www.youtube.com/embed/" + product.youtube}
								title="YouTube video player"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
								allowFullScreen=""
							></iframe>
						</div>
					)}
					<div className="allergens-ingredients">
						{product.allergens.length > 0 && (
							<div>
								<div className="title">
									<Label number={22} />
								</div>
								<div className="allergens">
									<Allergens
										allergens={product.allergens}
										color="#000"
										backgroundColor="#ccc"
									/>
								</div>
							</div>
						)}
						{product.ingredients.length > 0 && (
							<div className="ingredients">
								<div className="title">
									<Label number={21} />
								</div>
								<div className="smoot">
									{product.ingredients.map((ingredient, i) => (
										<span key={i}>
											{getTranslatedString(ingredient, "name")}
											{i < product.ingredients.length - 1 && " | "}
										</span>
									))}
								</div>
							</div>
						)}
					</div>
					{product.variants_categories.map((variants_category, i) => (
						<div key={i} id={"vc-" + variants_category.id}>
							<div className="title">
								{getTranslatedString(variants_category, "name")}
							</div>
							<div className="small smoot min_max">
								<Label number={19} />: {variants_category.min} -{" "}
								<Label number={20} />: {variants_category.max}
							</div>
							<div className="small error">
								<Label number={92} />
							</div>
							{variants_category.max == 1 && variants_category.force_mono == 0 ? (
								<Radio
									variants_category={variants_category}
									selectedVariants={selectedVariants}
									setSelectedVariants={setSelectedVariants}
									selectedSubroducts={selectedSubroducts}
									setSelectedSubroducts={setSelectedSubroducts}
								/>
							) : (
								<>
									<div className="variants-categories">
										{variants_category.variants.map((variant, n) => (
											<div key={n} className="variant-line">
												{product.disabled != 1 && (
													<PlusMinus
														qty={getVariantQty(
															variant.id,
															variants_category.id
														)}
														onCange={(qty) =>
															editVariant(
																variant.id,
																variants_category.id,
																qty,
																variant.price
															)
														}
														disabled={
															variants_category.force_mono == 1
																? getVariantQty(
																		variant.id,
																		variants_category.id
																  ) >= 1
																: getCategoryQty(
																		variants_category.id
																  ) >= variants_category.max
														}
													/>
												)}
												<div>
													{getTranslatedString(variant, "name")}
													{variants_category.has_price == 1 && (
														<span>
															{"+" +
																currencyFormat(
																	variant.price,
																	cart?.shop?.currency
																)}
														</span>
													)}
												</div>
											</div>
										))}
									</div>
									<div className="variants-categories">
										{variants_category.variants2.map((variant, n) => (
											<div key={n} className="variant-line">
												{product.disabled != 1 && (
													<PlusMinus
														qty={getVariantQty(
															variant.id,
															variants_category.id
														)}
														onCange={(qty) =>
															editVariant(
																variant.id,
																variants_category.id,
																qty,
																variant.price
															)
														}
														disabled={
															variants_category.force_mono == 1
																? getVariantQty(
																		variant.id,
																		variants_category.id
																  ) >= 1
																: getCategoryQty(
																		variants_category.id
																  ) >= variants_category.max
														}
													/>
												)}
												<div>
													{getTranslatedString(variant, "name")}
													{variants_category.has_price == 1 && (
														<span>
															{"+" +
																currencyFormat(
																	variant.price,
																	cart?.shop?.currency
																)}
														</span>
													)}
												</div>
											</div>
										))}
									</div>
									<div className="variants-categories">
										{variants_category.products.map((subproduct, n) => (
											<div key={n} className="variant-line">
												{product.disabled != 1 && (
													<PlusMinus
														qty={getSubproductQty(
															subproduct.id,
															variants_category.id
														)}
														onCange={(qty) =>
															editSubproduct(
																subproduct.id,
																variants_category.id,
																qty,
																subproduct.price
															)
														}
														disabled={
															variants_category.force_mono == 1
																? getSubproductQty(
																		subproduct.id,
																		variants_category.id
																  ) >= 1
																: getCategoryQty(
																		variants_category.id
																  ) >= variants_category.max
														}
													/>
												)}
												<div>
													{getTranslatedString(subproduct, "name")}
													<span>
														{variants_category.has_price == 1 &&
															"+" +
																currencyFormat(
																	subproduct.price,
																	cart?.shop?.currency
																)}
													</span>
												</div>
											</div>
										))}
									</div>
								</>
							)}
						</div>
					))}
					{hasRemovableIngredients(product) && (
						<div>
							<div className="title">
								<Label number={175} />
							</div>
							<div className="small smoot">
								<Label number={176} />
							</div>
							{product.ingredients.length > 0 && (
								<div>
									{product.ingredients.map(
										(ingredient, i) =>
											ingredient.removable == 1 && (
												<div key={i} className="ingredient-line">
													<div>
														<input
															type="checkbox"
															id={"ing-" + ingredient.id}
															defaultChecked={true}
															value={
																"No " +
																getTranslatedString(
																	ingredient,
																	"name"
																)
															}
														/>
													</div>
													<label htmlFor={"ing-" + ingredient.id}>
														{getTranslatedString(ingredient, "name")}
													</label>
												</div>
											)
									)}
								</div>
							)}
						</div>
					)}
					{product.mandatory_notes == 1 && (
						<div>
							<div className="title">
								<Label number={194} />
							</div>
							<textarea
								id="product-notes"
								value={productNotes}
								onChange={(e) => setProductNotes(e.target.value)}
							></textarea>
						</div>
					)}
				</div>
				{product.disabled == 0 && (
					<div className="footer">
						<button
							className="button button-primary"
							onClick={handleAddProductndVariants}
						>
							<Label number={18} /> - {currencyFormat(total, cart?.shop?.currency)}
						</button>
					</div>
				)}
			</PopUp>
		</>
	);
}

function Radio({
	variants_category,
	selectedVariants,
	setSelectedVariants,
	selectedSubroducts,
	setSelectedSubroducts,
}) {
	const { cart } = useContext(AppContext);

	const editVariants = (event, variant) => {
		console.log("editVariants");
		const el = event.target;
		//console.log("checked", el.classList.contains("checked"));
		const wasChecked = el.classList.contains("checked");
		/*document
			.querySelectorAll("[name=" + el.name + "]")
			.forEach((e) => e.classList.remove("checked"));*/
		//console.log("checked", el.classList.contains("checked"));
		const newArr = [];
		for (const selectedVariant of selectedVariants) {
			if (selectedVariant.variant_category_id != variants_category.id)
				newArr.push(selectedVariant);
		}
		if (!wasChecked) {
			//el.classList.add("checked");
			newArr.push({
				id: variant.id,
				qty: 1,
				variant_category_id: variants_category.id,
				price: variant.price,
			});
		}
		setSelectedVariants(newArr);
	};

	const isCheckedVariants = (variantsCategoryId, variantId) => {
		for (const selectedVariant of selectedVariants) {
			if (
				selectedVariant.variant_category_id == variantsCategoryId &&
				selectedVariant.id == variantId
			)
				return " checked";
		}
	};

	const editSubproducts = (event, product) => {
		console.log("editSubproducts");
		const el = event.target;
		//console.log("checked", el.classList.contains("checked"));
		const wasChecked = el.classList.contains("checked");
		/*document
			.querySelectorAll("[name=" + el.name + "]")
			.forEach((e) => e.classList.remove("checked"));*/
		//console.log("checked", el.classList.contains("checked"));
		const newArr = [];
		for (const selectedSubroduct of selectedSubroducts) {
			if (selectedSubroduct.variant_category_id != variants_category.id)
				newArr.push(selectedSubroduct);
		}
		if (!wasChecked) {
			//el.classList.add("checked");
			newArr.push({
				id: product.id,
				qty: 1,
				variant_category_id: variants_category.id,
				price: product.price,
			});
		}
		setSelectedSubroducts(newArr);
	};

	const isCheckedSubproducts = (variantsCategoryId, subproductId) => {
		for (const selectedSubroduct of selectedSubroducts) {
			if (
				selectedSubroduct.variant_category_id == variantsCategoryId &&
				selectedSubroduct.id == subproductId
			)
				return " checked";
		}
	};

	return (
		<div className="variants-categories radio">
			{variants_category.variants.map((variant, i) => (
				<div
					key={"v-" + i}
					className="variant-line"
					data-id={variant.id}
					data-price={variant.price}
				>
					<button
						id={"var-" + variants_category.id + "-" + variant.id}
						name={"var-" + variants_category.id}
						className={"radio" + isCheckedVariants(variants_category.id, variant.id)}
						onClick={(e) => editVariants(e, variant)}
					/>
					<label
						htmlFor={"var-" + variants_category.id + "-" + variant.id}
						className="variant-title"
					>
						{variant.name}
						{variants_category.has_price == 1 && (
							<span>
								{" +" + currencyFormat(variant.price, cart?.shop?.currency)}
							</span>
						)}
					</label>
				</div>
			))}
			{variants_category.variants2.map((variant, i) => (
				<div
					key={"v2-" + i}
					className="variant-line"
					data-id={variant.id}
					data-price={variant.price}
				>
					<button
						id={"var-" + variants_category.id + "-" + variant.id}
						name={"var-" + variants_category.id}
						className={"radio" + isCheckedVariants(variants_category.id, variant.id)}
						onClick={(e) => editVariants(e, variant)}
					/>
					<label
						htmlFor={"var-" + variants_category.id + "-" + variant.id}
						className="variant-title"
					>
						{variant.name}
						{variants_category.has_price == 1 && (
							<span>
								{" +" + currencyFormat(variant.price, cart?.shop?.currency)}
							</span>
						)}
					</label>
				</div>
			))}
			{variants_category.products.map((subproduct, i) => (
				<div
					key={"s-" + i}
					className="variant-line subproduct"
					data-id={subproduct.id}
					data-price={subproduct.price}
				>
					<button
						id={"var-" + variants_category.id + "-" + subproduct.id}
						name={"var-" + variants_category.id}
						className={
							"radio" + isCheckedSubproducts(variants_category.id, subproduct.id)
						}
						onClick={(e) => editSubproducts(e, subproduct)}
					/>
					<label
						htmlFor={"var-" + variants_category.id + "-" + subproduct.id}
						className="variant-title"
					>
						{subproduct.name}
						{variants_category.has_price == 1 && (
							<span>
								{" +" + currencyFormat(subproduct.price, cart?.shop?.currency)}
							</span>
						)}
					</label>
				</div>
			))}
		</div>
	);
}

export default ProductSheet;
