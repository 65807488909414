import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../App";
import { Link } from "react-router-dom";
import "./style.css";
import Label from "../Label";

function FeaturedProducts() {
	const { courses } = useContext(AppContext);
	const [categories, setCategories] = useState(false);
	const [products, setProducts] = useState(false);

	useEffect(() => {
		if (!courses) return;
		let res = [];
		for (const course of courses) {
			for (const category of course.categories) {
				if (category.featured == 1) res.push(category);
			}
		}
		if (res.length > 0) setCategories(res);

		res = [];
		for (const course of courses) {
			for (const category of course.categories) {
				for (const product of category.products) {
					if (product.featured == 1) res.push(product);
				}
			}
		}
		if (res.length > 0) setProducts(res);
	}, [courses]);

	if (!products && !categories) return null;

	return (
		<>
			<h3 className="featured-title">
				<Label number={171} />
			</h3>
			<div className="featured">
				{categories &&
					categories.map((category, i) => (
						<Link key={i} className="card" to={"/store/" + category.id}>
							<div className="img">
								<img src={category.img} />
							</div>
							<div className="title">{category.name}</div>
							<div className="description">{category.description}</div>
						</Link>
					))}
				{products &&
					products.map((product, i) => (
						<Link key={i} className="card" to={"/store/" + product.category_id}>
							<div className="img">
								<img src={product.img} />
							</div>
							<div className="title">{product.name}</div>
							<div
								className="description"
								dangerouslySetInnerHTML={{
									__html: product.description,
								}}
							/>
						</Link>
					))}
			</div>
		</>
	);
}

export default FeaturedProducts;
