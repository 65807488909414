import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import Template2 from "../templates/shops/Template2";
import Template1 from "../templates/shops/Template1";

function Shops() {
	const { settings, shops } = useContext(AppContext);

	useEffect(() => {
		const scrollContainer = document.getElementById("main");
		scrollContainer.scrollTo(0, 0);
	}, []);

	if (!shops) return null;

	if (settings.website.shops_list_template == 2) return <Template2 shops={shops} />;
	else return <Template1 shops={shops} />;
}

export default Shops;
