import { useContext } from "react";
import { useGoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { AppContext } from "../App";
import api from "./api";
import { setCookie } from "./functions";

function GoogleLoginButton() {
	const { lang, tempId, setConfirm, setUser, track, loginRedirect } = useContext(AppContext);
	const navigate = useNavigate();

	const login = useGoogleLogin({
		onSuccess: (tokenResponse) => handleSuccess(tokenResponse),
	});

	const handleSuccess = async (responseGoogle) => {
		console.log("handleSuccess");
		console.log(responseGoogle);

		const user = await fetch(
			"https://www.googleapis.com/oauth2/v1/userinfo?access_token=" +
				responseGoogle.access_token,
			{
				method: "GET",
				headers: {
					Authorization: responseGoogle.access_token,
				},
			}
		);
		const decoded_user = await user.json();
		console.log(decoded_user);

		const data = {
			name: decoded_user.given_name,
			surname: decoded_user.family_name,
			email: decoded_user.email,
			id: decoded_user.id,
			temp_cart_id: tempId,
			customer_lang: lang,
		};

		const loginRes = await api.post("/customers/google_login/", data);
		console.log(loginRes);
		if (loginRes.success == 0)
			setConfirm({
				message: loginRes.error,
				confirmMessage: "Chiudi",
			});
		else {
			api.setToken(loginRes.token);
			const decoded = jwt_decode(loginRes.token);
			console.log(loginRes.token);
			console.log(decoded);
			localStorage.setItem("website-user", loginRes.token);
			const response = await api.getProtected("/customers/get/" + decoded.user_id + "/");
			if (response.success == 0) alert(response.error);
			else setUser(response.data);
			if (loginRedirect) navigate(loginRedirect);
			else navigate("/");
			track();
		}
	};

	const handleFailure = (response) => {
		console.log("handleFailure");
		console.log(response);
		setConfirm({
			message: "Accesso Fallito",
			message: "Si è verificato un errore nell'accesso con Google",
			confirmMessage: "Chiudi",
		});
	};

	return (
		<button className="button button-primary" onClick={() => login()}>
			Sign in with Google 🚀{" "}
		</button>
	);
}

export default GoogleLoginButton;
