import { useContext } from "react";
import Label from "../components/Label";
import { AppContext } from "../App";

function BookingConfirmed() {
	const { lastOrderId } = useContext(AppContext);

	return (
		<div className="page">
			<div className="container">
				<h1>
					<Label number={84} />
				</h1>
				<p>
					<Label number={152} />
				</p>
				<p>
					<Label number={153} /> {lastOrderId}
				</p>
			</div>
		</div>
	);
}

export default BookingConfirmed;
