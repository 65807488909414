export default function SemiSesamo({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m92.12,256c1.84-54.56,16.62-98.5,40.14-140.09,4.88-8.63,7.63-19.32,8.37-29.28,1.65-22.21,8.93-30.42,30.46-30.45,21.5-.03,29.03,8.54,30.8,30.22.89,10.93,4.51,22.36,9.69,32.08,26.38,49.56,44.22,100.97,35.39,158.09-7.97,51.49-47.75,79.2-95.41,67.73-27.8-6.69-44.29-25.52-52.43-51.62-4.3-13.82-5.53-28.59-7.01-36.69Z"
				style={style}
			/>
			<path
				d="m168.9,371.58c27.54-1.27,51.72-8.5,70.98-27.78,22.67-22.7,31.77-51.25,33.34-82.2.57-11.14,4.57-16.38,14.44-19.3,9.66-2.86,18.91-7.37,28.71-9.42,16.07-3.36,30.12-8.5,41.87-21.4,10.79-11.84,23.81-9.37,36.98,3.52,14.19,13.89,17.23,26.78,5.11,37.94-13.25,12.21-17.53,27.11-22.04,43.21-11.88,42.43-30.97,81.62-63.77,111.57-15.19,13.87-34.1,25.71-53.42,32.61-42.58,15.2-90.98-21.43-92.21-68.75Z"
				style={style}
			/>
		</svg>
	);
}
