import { useContext, useEffect, useState } from "react";
import Label from "../components/Label";
import { AppContext } from "../App";
import api from "../components/api";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
import Privacy from "../components/Privacy";
import Marketing from "../components/Marketing";
import Password from "../components/form/Password";

function Booking() {
	const queryParams = new URLSearchParams(window.location.search);
	const { settings, setLoading, lang, user, setConfirm, setLastOrderId, track } =
		useContext(AppContext);
	const [cartId, setCartId] = useState(
		localStorage.getItem("website-reservations-cart-id")
			? localStorage.getItem("website-reservations-cart-id")
			: false
	);
	const [cart, setCart] = useState(false);
	const [newsletter, setNewsletter] = useState(false);
	const [marketing, setMarketing] = useState(false);
	const [registrationRequired, setRegistrationRequired] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		initCart(); // eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (cartId) {
			localStorage.setItem("website-reservations-cart-id", cartId);
			if (queryParams.get("rwg_token")) editCart({ rwg_token: queryParams.get("rwg_token") });
			else loadCart();
		}
	}, [cartId]);

	const initCart = async () => {
		console.log("initCart", cartId);
		if (!cartId) {
			setLoading(true);
			const response = await api.post("/reservations_cart/open/", { lang: lang });
			setLoading(false);
			if (response.success === 0) alert(response.error);
			else setCartId(response.cart_id);
		}
	};

	const loadCart = async () => {
		console.log("loadCart");
		setLoading(true);
		const response = await api.post("/reservations_cart/get/" + cartId + "/", {
			lang: lang,
		});
		setLoading(false);
		if (response.success === 0) alert(response.error);
		else setCart(response.data);
	};

	const editCart = async (request) => {
		console.log("editCart", request);
		setLoading(true);
		const response = await api.post("/reservations_cart/edit/" + cartId + "/", request);
		if (response.success === 0) {
			setLoading(false);
			alert(response.error);
		} else loadCart();
	};

	const checkout = async (e) => {
		console.log("checkout");
		e.preventDefault();
		const data = new FormData(e.target);
		let request = Object.fromEntries(data.entries());
		if (user) request.customer_id = user.id;
		request.newsletter = newsletter ? 1 : 0;
		request.marketing = marketing ? 1 : 0;
		request.event = getSelectedEvents();
		setConfirm({
			title: <Label number={38} />,
			message: <Label number={164} />,
			onConfirm: () => apiCheckout(request),
		});
	};

	const apiCheckout = async (request) => {
		console.log("apiCheckout");
		setLoading(true);
		const response = await api.post("/reservations_cart/checkout/" + cartId + "/", request);
		//await reloadUser();
		setLoading(false);
		if (response.success === 0) {
			alert(response.error);
		} else {
			setLastOrderId(response.id);
			navigate("/booking_confirmed");
			track();
		}
	};

	const getSelectedEvents = () => {
		const events = document.getElementsByClassName("event");
		let response = [];
		for (var i = 0; i < events.length; i++) {
			console.log(events[i]);
			if (events[i].checked) response.push(events[i].value);
		}
		return response;
	};

	if (!cart) return null;

	return (
		<div className="page">
			<h1 id="page-header">
				<Label number={2} />
			</h1>
			<div className="container">
				<form onSubmit={checkout} className="booking">
					<div>
						<Label number={151} />
					</div>
					<div>
						<div className="label">
							<Label number={32} />
						</div>
						<div className="buttons-list">
							{cart.availables_shops.map((shop, i) => (
								<button
									type="button"
									key={i}
									onClick={() => editCart({ shop_id: shop.id })}
									className={cart.shop_id == shop.id ? "selected" : ""}
								>
									{shop.name}
									<div className="subtitle">
										{shop.address + " " + shop.number + ", " + shop.city}
									</div>
								</button>
							))}
						</div>
					</div>
					{cart.shop_id && (
						<>
							<div>
								<div className="label">
									<Label number={150} />
								</div>
								<div className="buttons-list square">
									{[
										...Array(
											cart.shop.max_covers
												? parseInt(cart.shop.max_covers)
												: 20
										),
									].map((cover, i) => (
										<button
											type="button"
											key={i}
											onClick={() => editCart({ covers: i + 1 })}
											className={cart.covers == i + 1 ? "selected" : ""}
										>
											{i + 1}
										</button>
									))}
								</div>
							</div>
							{settings.reservations.enable_rooms == 1 && (
								<div>
									<div className="label">
										<Label number={180} />
									</div>
									<div className="buttons-list">
										{settings.reservations.disable_all_rooms_button != 1 && (
											<button
												type="button"
												onClick={() => editCart({ room_id: null })}
												className={!cart.room_id ? "selected" : ""}
											>
												<Label number={181} />
											</button>
										)}
										{cart.availables_rooms.map((room, i) => (
											<button
												type="button"
												key={i}
												onClick={() => editCart({ room_id: room.id })}
												className={
													cart.room_id == room.id ? "selected" : ""
												}
											>
												{room.name}
											</button>
										))}
									</div>
								</div>
							)}
							<div>
								<div className="label">
									<Label number={10} />
								</div>
								<input
									type="date"
									min={moment().format("YYYY-MM-DD")}
									max={
										settings.reservations.availables_days
											? moment()
													.add(
														parseInt(
															settings.reservations.availables_days
														) - 1,
														"days"
													)
													.format("YYYY-MM-DD")
											: "false"
									}
									value={moment(cart.date).format("YYYY-MM-DD")}
									onChange={(e) => editCart({ date: e.target.value })}
								/>
							</div>
							<div>
								<div className="label">
									<Label number={11} />
								</div>
								{cart.availables_times.length > 0 ? (
									<div className="buttons-list">
										{cart.availables_times.map((time, i) =>
											time.enabled == 1 ? (
												<button
													type="button"
													key={i}
													onClick={() => editCart({ date: time.value })}
													className={
														cart.date == time.value ? "selected" : ""
													}
												>
													{time.label}
													{settings.reservations.disable_label != 1 && (
														<div className="subtitle">
															{cart.shop
																.reservations_disable_table_assignment ==
															1
																? time.free_covers +
																  " coperti disponibili"
																: time.tables_count +
																  " tavoli disponibili"}
														</div>
													)}
												</button>
											) : (
												<button type="button" key={i} className="disabled">
													{time.label}
													<div className="subtitle">
														{time.disabled_message}
													</div>
												</button>
											)
										)}
									</div>
								) : (
									<div className="error">
										<Label number={114} />
									</div>
								)}
							</div>
							{cart.availables_times.length > 0 && (
								<>
									<div>
										<div className="label">
											<Label number={90} />
										</div>
										<div className="form-grid">
											<div className="input-group">
												<label>
													<Label number={54} />
												</label>
												<input
													type="text"
													name="name"
													defaultValue={user.name}
												/>
											</div>
											<div className="input-group">
												<label>
													<Label number={55} />
												</label>
												<input
													type="text"
													name="surname"
													defaultValue={user.surname}
													required
												/>
											</div>
											<div className="input-group">
												<label>
													<Label number={56} />
												</label>
												<input
													type="tel"
													name="mobile"
													defaultValue={user.mobile}
													required
												/>
											</div>
											{registrationRequired &&
												settings.customers
													?.show_birth_date_in_registration == 1 && (
													<div className="input-group">
														<label>
															<Label number={130} />
														</label>
														<input
															type="date"
															name="birth_date"
															defaultValue={user.birth_date}
															required={
																settings.customers
																	?.birth_date_required == 1
															}
														/>
													</div>
												)}
											<div className="input-group">
												<label>
													<Label number={91} />
												</label>
												<input
													type="email"
													name="email"
													defaultValue={user.email}
													required
												/>
											</div>
											{!user && (
												<div className="checkbox">
													<input
														id="registration_required"
														name="registration_required"
														type="checkbox"
														value={1}
														checked={registrationRequired}
														onChange={() =>
															setRegistrationRequired(
																registrationRequired ? false : true
															)
														}
													/>
													<label htmlFor="registration_required">
														<Label number={156} />
													</label>
												</div>
											)}
											{registrationRequired && (
												<div className="input-group">
													<label>
														<Label number={125} />
													</label>
													<Password id="password" name="password" />
												</div>
											)}
										</div>
									</div>
									{cart.shop.reservations_events && (
										<div>
											<div className="label">
												<Label number={155} />
											</div>
											<div className="buttons-list">
												{cart.shop.reservations_events
													.split("\r\n")
													.map((event, i) => (
														<div key={i} className="checkbox">
															<input
																id={"event-" + i}
																type="checkbox"
																value={event}
																className="event"
															/>
															<label htmlFor={"event-" + i}>
																{event}
															</label>
														</div>
													))}
											</div>
										</div>
									)}
									<div>
										<div className="label">
											<Label number={154} />
										</div>
										<textarea name="notes" />
									</div>
								</>
							)}
						</>
					)}
					<div>
						<Marketing
							marketing={marketing}
							setMarketing={setMarketing}
							newsletter={newsletter}
							setNewsletter={setNewsletter}
						/>
						<Privacy />
						{cart.errors.length > 0 ? (
							<button type="button" className="checkout-button disabled">
								{cart.errors[0].message}
							</button>
						) : (
							<button type="submit" className="checkout-button">
								<Label number={2} />
							</button>
						)}
					</div>
				</form>
			</div>
		</div>
	);
}

export default Booking;
