import { useContext } from "react";
import { AppContext } from "../App";
import Label from "./Label";

function Privacy() {
	const { settings, setPagePopup } = useContext(AppContext);

	return (
		<div className="privacy">
			<Label number={102} />{" "}
			<button type="button" onClick={() => setPagePopup(settings.orders.privacy_page_id)}>
				<Label number={103} />
			</button>{" "}
			<Label number={104} />{" "}
			<button type="button" onClick={() => setPagePopup(settings.orders.tos_page_id)}>
				<Label number={105} />
			</button>
		</div>
	);
}

export default Privacy;
