import { useContext, useEffect } from "react";
import Label from "../components/Label";
import { AppContext } from "../App";
import ReactGA from "react-ga4";

function OrderConfirmed() {
	const { lastOrderId, setLastOrderId, lastOrderTotal, settings } = useContext(AppContext);

	useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
		const orderId = queryParams.get("order_id");
		console.log(orderId);
		if (orderId) {
			setLastOrderId(orderId);
			track_purchase(lastOrderTotal, orderId);
		} else {
			track_purchase(lastOrderTotal, lastOrderId);
		}
	}, []);

	function track_purchase(value, transaction_id) {
		if (settings && settings.website.google_analytics) {
			ReactGA.event("purchase", {
				currency: "EUR",
				value: value,
				transaction_id: transaction_id,
			});
		}
	}

	return (
		<div className="page">
			<div className="container">
				<h1>
					<Label number={84} />
				</h1>
				<p>
					<Label number={85} />
				</p>
				<p>
					<Label number={86} /> {lastOrderId}
				</p>
			</div>
		</div>
	);
}

export default OrderConfirmed;
