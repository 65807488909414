import Label from "../components/Label";
import api from "../components/api";
import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import UserboardMenu from "../components/UserboardMenu";
import moment from "moment/moment";

function PointsTransactions() {
	const { settings, setLoading, setConfirm, user } = useContext(AppContext);
	const [transactions, setTransactions] = useState([]);
	const [balance, setBalance] = useState(0);

	useEffect(() => {
		loadPointsTransactions(); // eslint-disable-next-line
	}, []);

	const loadPointsTransactions = async () => {
		const response = await api.postProtected("/points_transactions/list/", {
			orderby: "date_insert DESC",
			src: [
				{ name: "customer_id", value: user.id, compare: "equal" },
				{ name: "expired", value: 0, compare: "equal" },
			],
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else {
			setTransactions(response.rows);
			let tot = 0;
			for (const el of response.rows) tot += parseInt(el.points);
			setBalance(tot);
		}
	};

	return (
		<div className="page">
			<div className="userboard">
				<UserboardMenu selected="points_transactions" />
				{settings.fidelity.split_points == 1 ? (
					<SplitList transactions={transactions} />
				) : (
					<GlobalList balance={balance} transactions={transactions} />
				)}
			</div>
		</div>
	);
}

function GlobalList({ balance, transactions }) {
	return (
		<>
			<div className="box highlight columns text-xl">
				<div>
					<Label number={120} />
				</div>
				<div>{balance}</div>
			</div>
			<div className="box">
				<h1>
					<Label number={115} />
				</h1>
				<div className="transactions-list">
					<>
						<div>
							<Label number={116} />
						</div>
						<div>
							<Label number={117} />
						</div>
						<div>
							<Label number={118} />
						</div>
					</>
					{transactions &&
						transactions.map((transaction, i) => (
							<Fragment key={i}>
								<div>
									{transaction.name}
									<div className="small">
										{moment(
											transaction.date_insert,
											"YYYY-MM-DD HH:mm:ss"
										).format("DD/MM/YYYY HH:mm")}
									</div>
								</div>
								<div className="center">{transaction.order_id}</div>
								<div className="center">{transaction.points}</div>
							</Fragment>
						))}
				</div>
			</div>
		</>
	);
}

function SplitList({ transactions = [] }) {
	const { settings, shops } = useContext(AppContext);
	const [data, setData] = useState([]);

	useEffect(() => {
		buildData(); // eslint-disable-next-line
	}, [transactions]);

	function buildData() {
		let r = [];
		for (const shop of shops) {
			r.push({
				name: shop.name,
				balance: getBalance(transactions, settings.mandant.id, shop.id),
				transactions: transactions.filter(
					(t) => t.mandant_id == settings.mandant.id && t.shop_id == shop.id
				),
			});
		}
		setData(r);
	}

	function getBalance(transactions, mandant_id, shop_id) {
		let balance = 0;
		for (const t of transactions) {
			if (t.mandant_id == mandant_id && t.shop_id == shop_id) balance += parseInt(t.points);
		}
		return balance;
	}
	return (
		<>
			{data.map((el, i) => (
				<Fragment key={i}>
					<div className="box highlight text-xl text-center">{el.name}</div>
					<div className="box columns text-l">
						<div>
							<Label number={120} />
						</div>
						<div>{el.balance}</div>
					</div>
					<div className="box">
						<h1>
							<Label number={115} />
						</h1>
						<div className="transactions-list">
							<>
								<div>
									<Label number={116} />
								</div>
								<div>
									<Label number={117} />
								</div>
								<div>
									<Label number={118} />
								</div>
							</>
							{el.transactions &&
								el.transactions.map((transaction, i) => (
									<Fragment key={i}>
										<div>
											{transaction.name}
											<div className="small">
												{moment(
													transaction.date_insert,
													"YYYY-MM-DD HH:mm:ss"
												).format("DD/MM/YYYY HH:mm")}
											</div>
										</div>
										<div className="center">{transaction.order_id}</div>
										<div className="center">{transaction.points}</div>
									</Fragment>
								))}
						</div>
					</div>
				</Fragment>
			))}
		</>
	);
}

export default PointsTransactions;
