import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../App";

function News() {
	const { news, getTranslatedString } = useContext(AppContext);
	const [el, setEl] = useState(false);
	let params = useParams();

	useEffect(() => {
		const p = news.find((el) => el.id == params.id);
		setEl(p);
	}, [params]);

	return (
		<div className="page">
			{el && (
				<>
					<h1 id="page-header">{getTranslatedString(el, "title")}</h1>
					<div className="container news-single">
						{el.img && (
							<div className="img">
								<img src={el.img} />
							</div>
						)}
						<div
							dangerouslySetInnerHTML={{ __html: getTranslatedString(el, "text") }}
						/>
					</div>
				</>
			)}
		</div>
	);
}

export default News;
