import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../App";
import api from "../components/api";
import Label from "../components/Label";
import { formatOrderStatus } from "../components/functions";
import moment from "moment/moment";

function BookingSheet() {
	const { setLoading, setConfirm, settings } = useContext(AppContext);
	const [reservation, setReservation] = useState(false);
	let params = useParams();

	useEffect(() => {
		loadReservation();
	}, []);

	const loadReservation = async () => {
		setLoading(true);
		const response = await api.get("/reservations/get/" + params.id + "/");
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setReservation(response.data);
	};

	const editReservation = async (id, request) => {
		setConfirm({
			title: <Label number={38} />,
			onConfirm: async () => {
				setLoading(true);
				const response = await api.post("/reservations/edit/" + id + "/", request);
				if (response.success == 0)
					setConfirm({
						title: "Errore",
						message: response.error,
					});
				else loadReservation();
			},
		});
	};

	return (
		<div className="page">
			{reservation && (
				<>
					<h1 id="page-header">
						<Label number={182} /> N°{reservation.id}
					</h1>
					<div className="container">
						<div>
							<Label number={9} />: {reservation.shop.name}
						</div>
						<div>
							<Label number={10} />:{" "}
							{moment(reservation.date, "YYYY-MM-DD HH:mm:ss").format(
								"DD/MM/YYYY HH:mm"
							)}
						</div>
						<div>
							<Label number={69} />:{" "}
							<Label number={formatOrderStatus(reservation.status)} />
						</div>
						<div>
							<Label number={70} />: {reservation.covers}
						</div>
						<div className="buttons horizontal">
							{(reservation.status == "unconfirmed" ||
								reservation.status == "confirmed") &&
								settings.reservations.cancellation_minutes &&
								moment(reservation.date, "YYYY-MM-DD HH:mm:ss")
									.subtract(settings.reservations.cancellation_minutes, "minutes")
									.format("X") -
									moment().format("X") >
									0 && (
									<button
										className="button button-primary"
										onClick={() =>
											editReservation(reservation.id, {
												status: "cancel_request",
											})
										}
									>
										<Label number={183} /> <i className="fas fa-trash"></i>
									</button>
								)}
						</div>
					</div>
				</>
			)}
		</div>
	);
}

export default BookingSheet;
