export default function Sedano({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m383.48,129.32c-6.12-21.47-28.96-34.43-50.63-28.6-4.17,1.12-8.19,2.81-12.05,4.15-20.4-30.54-37.48-33.67-71.44-13.78-28.52-20.57-50.11-16.55-70.27,14.09-3.09-1.23-5.5-2.28-7.98-3.15-23.28-8.17-47.87,4.07-54.53,27.12-6.88,23.81,7.56,48.95,32.79,51.83,22.32,2.54,34.07,15.18,39.85,34.04,4.77,15.57,7.87,32.15,8.46,48.4,1.34,36.6-.13,73.3,1.08,109.91.91,27.63,24.01,48.33,51.11,48.35,27.15.02,49.63-20.64,51.49-48.13.87-12.91.34-25.91.56-38.86.53-31.7-1.18-63.66,2.35-95.03,3.27-29.07,12.97-55.26,50.07-59.61,22.79-2.67,35.47-28.47,29.13-50.73Zm-155.26,65.38c-8.68-12.61-15.98-23.21-23.72-34.45,7.75.53,15.29,1.04,23.72,1.61v32.84Zm43.35.26v-32.84c8.1-.69,15.85-1.36,24.03-2.06-7.57,11-15.01,21.8-24.03,34.9Z"
				style={style}
			/>
		</svg>
	);
}
