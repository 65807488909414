import Label from "../components/Label";
import api from "../components/api";
import { Fragment, useContext, useEffect, useState } from "react";
import { AppContext } from "../App";
import UserboardMenu from "../components/UserboardMenu";
import moment from "moment/moment";
import { currencyFormat } from "../components/functions";

function CreditTransactions() {
	const { setLoading, setConfirm, user } = useContext(AppContext);
	const [transactions, setTransactions] = useState(false);
	const [balance, setBalance] = useState(0);

	useEffect(() => {
		loadCreditTransactions(); // eslint-disable-next-line
	}, []);

	const loadCreditTransactions = async () => {
		const response = await api.postProtected("/credits_transactions/list/", {
			orderby: "date_insert DESC",
			src: [{ name: "customer_id", value: user.id, compare: "equal" }],
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else {
			setTransactions(response.rows);
			let tot = 0;
			for (const el of response.rows) tot += parseFloat(el.price);
			setBalance(tot);
		}
	};

	return (
		<div className="page">
			<div className="userboard">
				<UserboardMenu selected="credits_transactions" />
				<div className="box highlight columns text-xl">
					<div>
						<Label number={121} />
					</div>
					<div>{currencyFormat(balance)}</div>
				</div>
				<div className="box">
					<h1>
						<Label number={122} />
					</h1>
					<div className="transactions-list">
						<>
							<div>
								<Label number={116} />
							</div>
							<div>
								<Label number={117} />
							</div>
							<div>
								<Label number={118} />
							</div>
						</>
						{transactions &&
							transactions.map((transaction, i) => (
								<Fragment key={i}>
									<div>
										{transaction.description}
										<div className="small">
											{moment(
												transaction.date_insert,
												"YYYY-MM-DD HH:mm:ss"
											).format("DD/MM/YYYY HH:mm")}
										</div>
									</div>
									<div className="center">{transaction.order_id}</div>
									<div className="right">{currencyFormat(transaction.price)}</div>
								</Fragment>
							))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default CreditTransactions;
