export default function Latte({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m390.9,223.38c-.09-7.96-3.34-16.98-7.9-23.6-14.1-20.48-29.02-40.47-44.66-59.8-9.02-11.15-13.25-22.53-12.59-37.03.87-19.26-7.58-26.76-27.18-26.8-31.68-.07-63.35-.04-95.03-.01-22.86.02-30.12,6.83-29.34,30.13.41,12.1-3.01,21.58-10.42,30.95-16.27,20.58-32.12,41.53-47.15,63.03-4.36,6.23-7.46,14.8-7.54,22.34-.61,58.38-.43,116.77-.28,175.15.05,18.51,7.95,26.05,26.75,26.1,38.01.09,76.02.03,114.04.03,38.01,0,76.02.05,114.04-.02,19.79-.04,27.49-7.35,27.54-26.68.13-57.93.37-115.87-.28-173.79Zm-43.23,151.81c0,1.26-.58,2.52-1.07,4.49h-84.84c-.31-3.85-.87-7.73-.88-11.6-.07-44.31-.28-88.62.22-132.93.07-6.21,2.53-13.25,6.08-18.4,11.28-16.34,23.59-31.96,37.15-50.05,14.64,19.62,28.18,37.55,41.37,55.74,1.66,2.29,1.99,5.94,2,8.97.09,47.93.04,95.85-.04,143.78Z"
				style={style}
			/>
		</svg>
	);
}
