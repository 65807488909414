import { useContext, useEffect, useState } from "react";
import { AppContext } from "../App";

export default function CmsPopups({ mode = "global", shopId = false }) {
	const { settings, popups, getTranslatedString } = useContext(AppContext);
	const [filtered, setFiltered] = useState([]);

	useEffect(() => {
		setFiltered(
			mode == "shop"
				? popups.filter((p) => p.shops.find((s) => s.id == shopId))
				: popups.filter((p) => p.shops.length == 0)
		);
	}, [shopId, popups]);

	const handleClose = (n) => {
		setFiltered(filtered.filter((el, i) => i != n));
	};

	if (filtered.length == 0) return null;

	return (
		<div className="cms-popups">
			{filtered.map(
				(popup, i) =>
					i == 0 && (
						<div className="cms-popup-content" key={i}>
							{popup.img && (
								<div className="img">
									<img src={popup.img} alt={popup.title} />
								</div>
							)}
							<div className="title">{getTranslatedString(popup, "title")}</div>
							<div
								dangerouslySetInnerHTML={{
									__html: getTranslatedString(popup, "text"),
								}}
							/>
							<button
								onClick={() => handleClose(i)}
								className="button button-primary"
							>
								{getTranslatedString(popup, "button_label")}
							</button>
						</div>
					)
			)}
		</div>
	);
}
