import { useContext, useEffect } from "react";
import api from "../api";
import { AppContext } from "../../App";

function Vivawallet({ onCancel }) {
	const { settings, cart, setLoading } = useContext(AppContext);

	useEffect(() => {
		pay();
	}, []);

	const pay = async () => {
		const request = {
			shop_id: cart.shop_id,
			total: cart.total,
			origin: "website",
			tags: [
				"origin:website",
				"logged:" + (cart.logged ? 1 : 0),
				"name:" + (cart.logged ? cart.customer.name : cart.name),
				"surname:" + (cart.logged ? cart.customer.surname : cart.surname),
				"email:" + (cart.logged ? cart.customer.email : cart.email),
			],
			customer_id: cart.customer_id,
		};
		console.log(request);
		setLoading(true);
		const response = await api.post("/vivawallet/init/", request);
		setLoading(false);
		console.log(response);
		if (response.success === 0) {
			onCancel();
			alert(response.error);
		} else {
			console.log(response.redirect_url);
			//window.location.replace(response.redirect_url);
			document.location = response.redirect_url;
		}
		return false;
	};

	return null;
}

export default Vivawallet;
