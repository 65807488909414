import { useNavigate } from "react-router-dom";
import Label from "../components/Label";
import api from "../components/api";
import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import Form from "../components/form";

function NewPassword() {
	const { setLoading, setConfirm, user } = useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (!user) navigate("/login");
	}, [user]);

	const handleCheckCode = async (request) => {
		setLoading(true);
		const loginRes = await api.postProtected("/customers/edit/" + user.id + "/", request);
		setLoading(false);
		if (loginRes.success == 0)
			setConfirm({
				title: "Errore",
				message: loginRes.error,
			});
		else {
			navigate("/userboard");
		}
	};

	return (
		<div className="page">
			<div className="login">
				<div className="content">
					<h1>
						<Label number={133} />
					</h1>
					<p>
						<Label number={134} />
					</p>
					<Form
						onSubmit={handleCheckCode}
						fields={[
							{
								type: "password",
								name: "password",
								label: <Label number={125} />,
								value: "",
								required: true,
							},
						]}
						submitButtonLabel={<Label number={78} />}
					/>
				</div>
			</div>
		</div>
	);
}

export default NewPassword;
