import { useContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { AppContext } from "../../App";
import api from "../api";

function Stripe({ onCancel }) {
	const { settings, cart, user, brand, tempId } = useContext(AppContext);
	const [isReady, setIsReady] = useState(false);
	const [options, setOptions] = useState(false);
	const [paymentId, setPaymentId] = useState(false);
	const [stripePromise, setStripePromise] = useState(false);

	useEffect(() => {
		loadOptions();
	}, []);

	useEffect(() => {
		if (options) setIsReady(true);
	}, [options]);

	const loadOptions = async () => {
		const request = {
			total: cart.total,
			shop_id: cart.shop_id,
			customer_id: user.id,
			customer_name: user.name,
			customer_surname: user.surname,
			customer_email: user.email,
			origin: "website",
			brand_id: brand.id,
			has_webook: 1,
			logged: user ? 1 : 0,
			temp_id: tempId,
		};
		console.log("createPaymentIntent", settings.mandant.id, request);
		const response = await api.postUnprotected(
			"/plugins/stripe/createPaymentIntent/" + settings.mandant.id + "/",
			request
		);
		console.log(response);
		setOptions({
			clientSecret: response.result.clientSecret,
		});
		setPaymentId(response.result.payment_id);

		const promise = loadStripe(response.result.publicKey);
		setStripePromise(promise);
	};

	if (!isReady || !paymentId) return <div>Loading</div>;

	return (
		<Elements stripe={stripePromise} options={options}>
			<CheckoutForm onCancel={onCancel} paymentId={paymentId} />
		</Elements>
	);
}

const CheckoutForm = ({ paymentId, onCancel }) => {
	const stripe = useStripe();
	const elements = useElements();
	const { setLoading, setLastOrderId, track, cart, setLastOrderTotal } = useContext(AppContext);
	const navigate = useNavigate();

	const handleSubmit = async (event) => {
		console.log("handleSubmit");
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		try {
			if (!stripe || !elements) {
				// Stripe.js has not yet loaded.
				// Make sure to disable form submission until Stripe.js has loaded.
				return;
			}

			setLoading(true);

			const result = await stripe.confirmPayment({
				//`Elements` instance that was used to create the Payment Element
				elements,
				confirmParams: {
					return_url: window.location.protocol + "//" + window.location.host,
				},
				redirect: "if_required",
			});
			console.log("stripe.confirmPayment", result);

			if (result.error) {
				// Show error to your customer (for example, payment details incomplete)
				setLoading(false);
				console.log(result.error.message);
			} else {
				check_payment(paymentId);
			}
		} catch (error) {
			setLoading(false);
			console.error("Loading error", error);
			// expected output: ReferenceError: nonExistentFunction is not defined
			// Note - error messages will vary depending on browser
		}
	};

	const check_payment = async (payment_id, retry = 1) => {
		console.log("stripe_check_payment", payment_id, retry);
		const res = await api.post("/stripe/check_payment/", {
			payment_id: payment_id,
		});
		console.log(res);
		if (res.success == 0) return alert(res.data.error);
		console.log(res.status);
		if (res.status == "pending")
			if (retry < 10) {
				setTimeout(function () {
					retry++;
					check_payment(payment_id, retry);
				}, 3000);
			} else {
				setLoading(false);
				alert("Sessione di pagamento scaduta");
			}
		else if (res.status == "confirmed") {
			setLoading(false);
			setLastOrderId(res.order_id);
			setLastOrderTotal(cart.total);
			navigate("/order_confirmed");
			track();
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />
			<div className="buttons-group">
				{stripe && elements && (
					<button className="button button-primary" disabled={!stripe}>
						Paga
					</button>
				)}
				<button className="button" type="button" onClick={onCancel}>
					Annulla
				</button>
			</div>
		</form>
	);
};

export default Stripe;
