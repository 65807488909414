import { useContext } from "react";
import { AppContext } from "../../App";
import "./style.css";

function CategoriesMenu1() {
	const { courses, getTranslatedString, category, setCategory } = useContext(AppContext);

	const scrollProducts = (category) => {
		console.log("scrollProducts");
		const scrollContainer = document.getElementById("main");
		const header = document.getElementById("header");
		const menu = document.getElementsByClassName("categories-menu-1-container")[0];
		const element = document.getElementById("category-" + category.id);
		console.log(
			element.offsetTop,
			header.offsetHeight,
			menu.offsetHeight,
			element.offsetTop - header.offsetHeight - menu.offsetHeight
		);
		if (element) {
			// 👇 Will scroll smoothly to the top of the next section
			//element.scrollIntoView({ top: 120, behavior: "smooth" });

			scrollContainer.scrollTo({
				behavior: "smooth",
				top: element.offsetTop - header.offsetHeight - menu.offsetHeight,
			});
		}
	};

	return (
		<div className="categories-menu-1-container screen-container">
			<div className="categories-menu-1">
				{courses.map((course, i) =>
					course.categories.map(
						(c, n) =>
							c.products.length > 0 && (
								<button
									key={n}
									onClick={() => {
										//setCategory(c);
										scrollProducts(c);
									}}
									id={"category-button-" + c.id}
									className={category.id == c.id ? "active" : ""}
								>
									{getTranslatedString(c, "name")}
								</button>
							)
					)
				)}
			</div>
		</div>
	);
}

export default CategoriesMenu1;
