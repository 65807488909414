import { useContext, useEffect } from "react";
import { AppContext } from "../../App";
import api from "../api";
import { useNavigate } from "react-router-dom";

function Nexi({ onCancel }) {
	const { settings, cart, setLoading, track, setLastOrderId, setLastOrderTotal } =
		useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		window.addEventListener("message", checkPayment, false);
		pay();
		return () => window?.removeEventListener("message", checkPayment);
	}, []);

	const checkPayment = (event) => {
		console.log("New message recived");
		console.log(event.data);
		if (event.data.status == "undo") onCancel();
		if (event.data.status == "failed") onCancel();
		if (event.data.status == "success") {
			setLastOrderId(event.data.id);
			setLastOrderTotal(cart.total);
			navigate("/order_confirmed");
			track();
		}
	};

	const pay = async () => {
		setLoading(true);
		const request = {
			cart_id: cart.customer_id,
			total: cart.total,
			shop_id: cart.shop_id,
			origin: "website",
			name: cart.logged ? cart.customer.name : cart.name,
			surname: cart.logged ? cart.customer.surname : cart.surname,
			email: cart.logged ? cart.customer.email : cart.email,
			logged: cart.logged ? 1 : 0,
		};
		console.log(request);
		const response = await api.post(
			"/plugins/xpay/init_payment.php?m_id=" + settings.mandant.id,
			request
		);
		console.log(response);
		if (response.success == 0) {
			setLoading(false);
			alert(response.error);
			return;
		}
		var mapForm = document.createElement("form");
		mapForm.target = "nexi-iframe";
		mapForm.method = "POST";
		mapForm.action = response.url;

		console.log(response.data);
		for (const [key, value] of Object.entries(response.data)) {
			var mapInput = document.createElement("input");
			mapInput.type = "text";
			mapInput.name = key;
			mapInput.value = value;
			mapForm.appendChild(mapInput);
		}
		document.body.appendChild(mapForm);
		mapForm.submit();
		mapForm.remove();
	};

	return (
		<div className="nexi-popup">
			<iframe
				className="nexi-iframe"
				name="nexi-iframe"
				onmessage={(event) => console.log(event)}
				onLoad={(e) => {
					console.log(e);
					setLoading(false);
				}}
			></iframe>
		</div>
	);
}

export default Nexi;
