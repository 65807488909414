import { useState } from "react";
import Marketing from "../Marketing";
import Privacy from "../Privacy";
import Password from "./Password";
import areaCodes from "../../data/areaCodes.json";

function Form({
	onSubmit,
	fields,
	submitButtonLabel,
	onBack,
	backButtonLabel,
	privacy,
	marketingBlock,
}) {
	const [newsletter, setNewsletter] = useState(false);
	const [marketing, setMarketing] = useState(false);

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		let request = Object.fromEntries(data.entries());
		if (marketingBlock) {
			request.newsletter = newsletter ? 1 : 0;
			request.marketing = marketing ? 1 : 0;
		}
		onSubmit(request);
	};
	return (
		<form onSubmit={handleSubmit}>
			{fields &&
				fields.map(
					(field, i) =>
						field.disabled != 1 && (
							<div key={i} className={"input-group type-" + field.type}>
								{field.label && (
									<label htmlFor={"form-" + field.name}>
										{field.label}
										{field.required ? "*" : ""}:
									</label>
								)}
								{(field.type == "text" ||
									field.type == "email" ||
									field.type == "date" ||
									field.type == "number") && (
									<input
										type={field.type}
										id={"form-" + field.name}
										name={field.name}
										defaultValue={field.value}
										required={field.required}
									/>
								)}
								{field.type == "tel" && (
									<div className="mobile-container">
										<select
											name="area_code"
											defaultValue={field.value.area_code}
											required
										>
											<option value=""></option>
											{areaCodes.map((el, i) => (
												<option key={i} value={el.dial_code}>
													{el.code + " (" + el.dial_code + ")"}
												</option>
											))}
										</select>
										<input
											type={field.type}
											id={"form-" + field.name}
											name={field.name}
											defaultValue={field.value.mobile}
											required={field.required}
										/>
									</div>
								)}
								{field.type == "password" && (
									<Password id={"form-" + field.name} name={field.name} />
								)}
								{field.type == "select" && (
									<select
										id={"form-" + field.name}
										name={field.name}
										defaultValue={field.value}
										required={field.required}
									>
										{field.options?.map((option, n) => (
											<option key={n} value={option.value}>
												{option.name}
											</option>
										))}
									</select>
								)}
							</div>
						)
				)}
			{marketingBlock && (
				<Marketing
					marketing={marketing}
					setMarketing={setMarketing}
					newsletter={newsletter}
					setNewsletter={setNewsletter}
					checkUser={false}
				/>
			)}
			{privacy && <Privacy />}
			<button className="button button-primary">{submitButtonLabel}</button>
			{backButtonLabel && (
				<button type="button" className="button" onClick={onBack}>
					{backButtonLabel}
				</button>
			)}
		</form>
	);
}

export default Form;
