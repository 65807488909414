import { useContext, useEffect, useState } from "react";
import { currencyFormat } from "./functions";
import { AppContext } from "../App";
import CheckIcon from "./icons/CheckIcon";

function PromoLevels({ mode = "full" }) {
	const { cart, discountsLimit } = useContext(AppContext);

	const getDiscountLabel = (discount) => {
		let label;
		if (discount.type === "perc") {
			label = "Sconto " + discount.value + "%";
		}
		if (discount.type === "fixed") {
			label = "Sconto " + currencyFormat(discount.value, cart?.shop?.currency);
		}
		if (discount.type === "product") {
			label = discount.product?.name + " omaggio";
		}
		return label;
	};

	const isActive = (discount) => {
		return cart.total_products >= discount.order_min;
	};

	const isFirstInactive = (i) => {
		//console.log("isFirstInactive");
		let first = -1;
		let n = 0;
		for (const d of discountsLimit) {
			//console.log(n, isActive(d));
			if (!isActive(d)) {
				first = n;
				break;
			}
			n++;
		}
		//console.log(first);
		if (i == first) return true;
		else return false;
	};

	const allActive = () => {
		//console.log(discountsLimit.find((d) => !isActive(d)));
		return !discountsLimit.find((d) => !isActive(d));
	};

	if (!discountsLimit || discountsLimit.length == 0) return null;

	if (mode == "slim" && allActive()) return null;

	return (
		<div className="promo-levels">
			{mode == "full" && (
				<div className="promo-levels-line title">
					<div>Prodotti e sconti automatici</div>
					<div>Soglia</div>
				</div>
			)}
			{discountsLimit.map(
				(discount, i) =>
					(mode == "full" || isFirstInactive(i)) && (
						<div
							key={i}
							className={
								"promo-levels-line" + (isActive(discount) ? " promo-activated" : "")
							}
						>
							<div>{getDiscountLabel(discount)}</div>
							<div>
								{mode == "slim" && !isActive(discount) && "arriva a "}
								{currencyFormat(discount.order_min, cart?.shop?.currency)}{" "}
								{isActive(discount) && <CheckIcon />}
							</div>
						</div>
					)
			)}
		</div>
	);
}

export default PromoLevels;
