import { useNavigate } from "react-router-dom";
import Label from "../components/Label";
import jwt_decode from "jwt-decode";
import api from "../components/api";
import { useContext, useEffect } from "react";
import { AppContext } from "../App";
import { setCookie, textLabel } from "../components/functions";
import areaCodes from "../data/areaCodes.json";

function LoginMobileLogin() {
	const { setLoading, setConfirm, setUser, tempId, tempUser, labels, lang, loginRedirect } =
		useContext(AppContext);
	const navigate = useNavigate();

	useEffect(() => {
		if (!tempUser) navigate("/check_mobile");
	}, [tempUser]);

	const login = async (e) => {
		console.log(e.target);
		e.preventDefault();
		const data = new FormData(e.target);
		let request = Object.fromEntries(data.entries());
		request.temp_cart_id = tempId;
		request.lang = lang;
		setLoading(true);
		const loginRes = await api.login(request);
		setLoading(false);
		if (loginRes.success == 0)
			setConfirm({
				title: "Errore",
				message: loginRes.error,
			});
		else {
			const decoded = jwt_decode(loginRes.token);
			localStorage.setItem("website-user", loginRes.token);
			const response = await api.getProtected("/customers/get/" + decoded.user_id + "/");
			if (response.success == 0) alert(response.error);
			else setUser(response.data);
			if (loginRedirect) navigate(loginRedirect);
			else navigate("/");
		}
	};

	const requestNewPasswordCode = async () => {
		console.log(tempUser);
		setLoading(true);
		const response = await api.post(
			"/customers/request_new_password_code/" + tempUser.id + "/",
			{ mode: "sms" }
		);
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else navigate("/check_password_code_sms");
	};

	return (
		<div className="page">
			<div className="login">
				<div className="content">
					<div>
						<h1>
							<Label number={191} />
						</h1>
					</div>
					<form onSubmit={login}>
						<div className="mobile-container">
							<select name="area_code" defaultValue={tempUser.area_code}>
								<option value=""></option>
								{areaCodes.map((el, i) => (
									<option key={i} value={el.dial_code}>
										{el.code + " (" + el.dial_code + ")"}
									</option>
								))}
							</select>
							<input
								type="tel"
								name="mobile"
								placeholder={textLabel(labels, 56, lang)}
								defaultValue={tempUser.mobile}
								required
							/>
						</div>
						<input
							type="password"
							name="password"
							placeholder="Password"
							autoComplete="current-password"
							required
						/>
						<button className="button button-primary">
							<Label number={36} />
						</button>
						<button
							type="button"
							className="button"
							onClick={() => requestNewPasswordCode()}
						>
							<Label number={132} />
						</button>
						<button
							type="button"
							className="button"
							onClick={() => navigate("/check_mobile")}
						>
							<Label number={131} />
						</button>
					</form>
				</div>
			</div>
		</div>
	);
}

export default LoginMobileLogin;
