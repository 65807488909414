import { Fragment, useContext, useEffect } from "react";
import { AppContext } from "../../App";
import "./style.css";

function CategoriesMenu2() {
	const { courses, getTranslatedString, category } = useContext(AppContext);

	useEffect(() => {
		const handleResize = () => {
			const el = document.getElementsByClassName("categories-menu-2")[0];
			if (window.innerWidth < 900) el.style.display = "none";
			else el.style.display = "flex";
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window?.removeEventListener("resize", handleResize);
		};
	}, []);

	const scrollProducts = (category) => {
		console.log("scrollProducts");
		const scrollContainer = document.getElementById("main");
		const header = document.getElementById("header");
		const button = document.getElementById("categories-menu-2-button");
		const element = document.getElementById("category-" + category.id);
		console.log(
			element.offsetTop,
			header.offsetHeight,
			button.offsetHeight,
			element.offsetTop - header.offsetHeight
		);
		if (element) {
			scrollContainer.scrollTo({
				behavior: "smooth",
				top: element.offsetTop - header.offsetHeight - button.offsetHeight,
			});
		}
	};

	const hideMenu = () => {
		const el = document.getElementsByClassName("categories-menu-2")[0];
		console.log(window.innerWidth);
		if (window.innerWidth < 900) el.style.display = "none";
	};

	return (
		<div className="categories-menu-2">
			<button className="close-button" onClick={hideMenu}>
				<i className="fas fa-times"></i>
			</button>
			{courses.map((course, i) => (
				<Fragment key={i}>
					<div className="course">{getTranslatedString(course, "name")}</div>
					<div className="list">
						{course.categories.map(
							(c, n) =>
								c.products.length > 0 && (
									<button
										key={n}
										onClick={() => {
											//setCategory(c);
											scrollProducts(c);
											hideMenu();
										}}
										id={"category-button-" + c.id}
										className={category.id == c.id ? "active" : ""}
									>
										{getTranslatedString(c, "name")}
									</button>
								)
						)}
					</div>
				</Fragment>
			))}
		</div>
	);
}

export default CategoriesMenu2;
