import { useContext } from "react";
import { AppContext } from "../../App";
import "./confirm.css";
import Label from "../Label";

function Confirm({}) {
	const { confirm, setConfirm } = useContext(AppContext);

	if (!confirm) return null;

	return (
		<div className="confirm">
			<div className="content">
				{confirm.title && <div className="title">{confirm.title}</div>}
				{confirm.message && <div className="message">{confirm.message}</div>}
				{confirm.onConfirm ? (
					<div className="buttons">
						<button onClick={() => setConfirm(false)}>
							<Label number={40} />
						</button>
						<button
							className="success"
							onClick={() => {
								setConfirm(false);
								confirm.onConfirm();
							}}
						>
							<Label number={39} />
						</button>
					</div>
				) : (
					<button className="success" onClick={() => setConfirm(false)}>
						Ok
					</button>
				)}
			</div>
		</div>
	);
}

export default Confirm;
