export default function AnidrideSolforosaSolfiti({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m400.27,402.51l-85.32-223.98v-89.41c7.43-2.64,12.78-9.72,12.78-18.05v-6.39c0-10.57-8.6-19.17-19.17-19.17h-127.81c-10.57,0-19.17,8.6-19.17,19.17v6.39c0,8.33,5.35,15.41,12.78,18.05v89.55l-75.12,225.36c-3.94,11.83-2.03,24.42,5.27,34.54,7.3,10.12,18.63,15.93,31.11,15.93h228.83c12.62,0,24.42-6.2,31.58-16.59,7.16-10.38,8.74-23.62,4.26-35.4Zm-199.64-161.76c1.58-3.16,5.41-4.42,8.57-2.86,27.3,13.65,50.2,7.77,72.36,2.08,16.99-4.37,33.04-8.5,48.54-2.3,3.28,1.31,4.87,5.03,3.56,8.31-1.31,3.28-5.03,4.87-8.31,3.56-11.61-4.64-25.05-1.2-40.61,2.81-12.06,3.1-25.73,6.61-40.78,6.61-12.57,0-26.1-2.45-40.47-9.64-3.16-1.58-4.44-5.42-2.86-8.57Zm-5.07,143.45c-13.21,0-23.96-10.75-23.96-23.96,0-3.53,2.86-6.39,6.39-6.39s6.39,2.86,6.39,6.39c0,6.17,5.02,11.18,11.18,11.18s11.18-5.02,11.18-11.18-6.12-11.43-18.17-19.24c-8.81-4.52-13.9-11.56-13.81-19.5.13-11.23,9.31-20.38,20.45-20.38,6.39,0,12.5,3.05,16.34,8.16,2.12,2.82,1.56,6.83-1.26,8.95-2.82,2.13-6.83,1.55-8.95-1.26-1.47-1.95-3.7-3.07-6.13-3.07-4.25,0-7.62,3.4-7.67,7.74-.05,4,5.19,7.15,7.45,8.31,13.67,8.82,24.54,16.96,24.54,30.27s-10.75,23.96-23.96,23.96Zm93.82-28.11c0,15.5-12.61,28.11-28.11,28.11h-1.3c-15.5,0-28.11-12.61-28.11-28.11v-26.86c0-15.5,12.61-28.11,28.11-28.11h1.3c15.5,0,28.11,12.61,28.11,28.11v26.86Zm44.73,40.89c3.53,0,6.39,2.86,6.39,6.39s-2.86,6.39-6.39,6.39h-25.56c-2.44,0-4.66-1.39-5.74-3.57-1.07-2.18-.81-4.79.67-6.72l24.09-31.24c1.5-2.47.38-5.17-.18-6.19-.57-1.02-2.27-3.41-5.78-3.41h-.7c-3.29,0-5.97,2.68-5.97,5.97,0,3.53-2.86,6.6-6.39,6.6s-6.39-2.65-6.39-6.18v-.42c0-10.34,8.41-18.75,18.75-18.75h.7c7.15,0,13.49,3.74,16.96,9.99,3.47,6.26,3.27,13.61-.51,19.68l-16.52,21.46h12.58Z"
				style={style}
			/>
			<path
				d="m259.97,313.91h1.3c8.46,0,15.33,6.87,15.33,15.33v26.86c0,8.46-6.87,15.33-15.33,15.33h-1.3c-8.46,0-15.33-6.87-15.33-15.33v-26.86c0-8.46,6.87-15.33,15.33-15.33Z"
				style={style}
			/>
		</svg>
	);
}
