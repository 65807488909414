export default function IconDotsVertical(props) {
	return (
		<svg
		  fill="currentColor"
		  viewBox="0 0 16 16"
		  height="2em"
		  width="2em"
		  {...props}
		>
		  <path d="M9.5 13a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm0-5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
		</svg>
	  );
}
