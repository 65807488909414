export default function Crostacei({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m264.15,220.39c3.95-.07,10.12-4.47,11.34-8.16,14.08-42.36,27.39-84.97,41.92-130.68-12.98-1.92-24.24-4.95-35.52-5.04-65.78-.5-131.56-.47-197.34-.24-18.49.07-27.32,9.95-27.13,28.41.62,59.34,51.51,112.66,111.84,115.53,31.57,1.5,63.26.74,94.88.17Zm-147.3-84.58c-8.36,0-10.31-8.22-10.27-13.18.03-4.06,1.48-15.13,10.9-15.17s11.17,11.07,11.03,14.97c-.17,4.85-2.53,13.37-11.66,13.37Z"
				style={style}
			/>
			<path
				d="m342.08,91.19c-.8.58-2.06,1.02-2.31,1.78-13.65,41.92-27.27,83.85-40.62,125.88-.73,2.3.07,6.15,1.66,7.96,24.22,27.6,30.12,30.05,68.38,29.83,12.53-.08,25.07-.02,37.6-.02,11.78,0,23.55,0,35.06,0,6.88-69.16-36.69-140.26-99.79-165.43Z"
				style={style}
			/>
			<path
				d="m278.95,340.93c-2.68,1.22-6.86-.59-10.29-1.27-15.74-3.11-31.36-7.74-47.23-8.9-8.4-.61-20.24,2.3-25.01,8.14-4.72,5.76-5.13,18.04-2.64,25.94,2.91,9.23,2.74,16.1-.03,25.3-2.39,7.94-1.42,19.85,3.26,26.09,4.11,5.48,15.78,8.39,23.54,7.49,27.85-3.23,55.5-8.29,83.12-13.21,10.23-1.82,20.19-5.16,33.15-8.56-9.96-16.17-18.57-29.67-26.67-43.46-7.06-12.02-12.34-26.18-31.19-17.58Z"
				style={style}
			/>
			<path
				d="m321.33,318.55c-1.31,3.07-1.46,7.97.12,10.72,11.67,20.31,23.92,40.29,36.85,61.81,42.46-26.79,68.98-61.61,80.05-109.12h-99.86c-6.35,13.34-12.15,24.79-17.16,36.58Z"
				style={style}
			/>
		</svg>
	);
}
