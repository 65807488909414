import { useContext, useEffect, useState, Fragment } from "react";
import Label from "../components/Label";
import { AppContext } from "../App";
import api from "../components/api";
import { currencyFormat } from "../components/functions";

function Promo() {
	const { cart, lang, setLoading, setConfirm, user } = useContext(AppContext);
	const [discountsFidelity, setDiscountsFidelity] = useState(false);
	const [discountsCode, setDiscountsCode] = useState(false);
	const [creditCodes, setCreditCodes] = useState(false);

	useEffect(() => {
		const scrollContainer = document.getElementById("main");
		scrollContainer.scrollTo(0, 0);
		loadDiscountsFidelity(); // eslint-disable-next-line
		loadDiscountsCode(); // eslint-disable-next-line
		if (user) {
			loadCreditCodes(); // eslint-disable-next-line
		}
	}, []);

	const loadDiscountsFidelity = async () => {
		const response = await api.post("/discounts_fidelity/list/", {
			orderby: "name",
			lang: lang,
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setDiscountsFidelity(response.rows);
	};

	const loadDiscountsCode = async () => {
		const response = await api.post("/discounts/list/", {
			src: [{ name: "public", value: 1, compare: "equal" }],
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setDiscountsCode(response.rows);
	};

	const loadCreditCodes = async () => {
		const response = await api.postProtected("/credits_codes/list/", {
			src: [{ name: "buyer_id", value: user.id, compare: "equal" }],
		});
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else setCreditCodes(response.rows);
	};

	return (
		<div className="page">
			<h1 id="page-header">
				<Label number={157} />
			</h1>
			<div className="container">
				{discountsFidelity && (
					<div>
						<h1>
							<Label number={158} />
						</h1>
						<div className="table table-3">
							<div className="table-head">
								<Label number={116} />
							</div>
							<div className="table-head text-center">
								<Label number={159} />
							</div>
							<div className="table-head text-center">
								<Label number={160} />
							</div>
							{discountsFidelity.map((discount, i) => (
								<Fragment key={i}>
									<div>{discount.name}</div>
									<div className="text-center">{discount.points}</div>
									<div className="text-center">
										{discount.value_type == "fixed" &&
											currencyFormat(discount.value, cart?.shop?.currency)}
										{discount.value_type == "perc" && discount.value + "%"}
									</div>
								</Fragment>
							))}
						</div>
					</div>
				)}
				{discountsCode && (
					<div>
						<h1>
							<Label number={161} />
						</h1>
						<div className="table table-3">
							<div className="table-head">
								<Label number={116} />
							</div>
							<div className="table-head text-center">
								<Label number={128} />
							</div>
							<div className="table-head text-center">
								<Label number={160} />
							</div>
							{discountsCode.map((discount, i) => (
								<Fragment key={i}>
									<div>{discount.name}</div>
									<div className="text-center">{discount.code}</div>
									<div className="text-center">
										{discount.value_type == "fixed" &&
											currencyFormat(discount.value, cart?.shop?.currency)}
										{discount.value_type == "perc" && discount.value + "%"}
									</div>
								</Fragment>
							))}
						</div>
					</div>
				)}
				{creditCodes && creditCodes.length > 0 && (
					<div>
						<h1>
							<Label number={162} />
						</h1>
						<div className="table table-4">
							<div className="table-head">
								<Label number={116} />
							</div>
							<div className="table-head text-center">
								<Label number={128} />
							</div>
							<div className="table-head text-center">
								<Label number={160} />
							</div>
							<div className="table-head text-center">
								<Label number={163} />
							</div>
							{creditCodes.map((discount, i) => (
								<Fragment key={i}>
									<div>{discount.name}</div>
									<div className="text-center">{discount.code}</div>
									<div className="text-center">
										{currencyFormat(discount.value, cart?.shop?.currency)}
									</div>
									<div className="text-center">
										{discount.used == 1 ? (
											<i className="fas fa-check success" />
										) : (
											<i className="fas fa-times error" />
										)}
									</div>
								</Fragment>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default Promo;
