import { useNavigate } from "react-router-dom";
import Label from "../components/Label";
import api from "../components/api";
import { useContext } from "react";
import { AppContext } from "../App";
import Form from "../components/form";

function SignUpMobile() {
	const { settings, setLoading, setConfirm, tempUser, setTempUser, tempId, lang } =
		useContext(AppContext);
	const navigate = useNavigate();

	const handleSignUp = async (request) => {
		request.has_code = 1;
		request.temp_cart_id = tempId;
		request.customer_lang = lang;
		request.mode = "sms";
		setLoading(true);
		const response = await api.post("/customers/add/", request);
		setLoading(false);
		if (response.success == 0)
			setConfirm({
				title: "Errore",
				message: response.error,
			});
		else {
			setTempUser(response.data);
			navigate("/check_code_mobile");
		}
	};

	return (
		<div className="page">
			<div className="login">
				<div className="content">
					<h1>
						<Label number={123} />
					</h1>
					<Form
						onSubmit={handleSignUp}
						submitButtonLabel={<Label number={124} />}
						onBack={() => navigate("/check_mobile")}
						backButtonLabel={<Label number={131} />}
						privacy={true}
						marketingBlock={true}
						fields={[
							{
								type: "text",
								name: "name",
								label: <Label number={54} />,
								value: "",
								required: true,
							},
							{
								type: "text",
								name: "surname",
								label: <Label number={55} />,
								value: "",
								required: true,
							},
							{
								type: "date",
								name: "birth_date",
								label: <Label number={130} />,
								value: "",
								required: settings.customers?.birth_date_required == 1,
								disabled: settings.customers?.show_birth_date_in_registration != 1,
							},
							{
								type: "tel",
								name: "mobile",
								label: <Label number={56} />,
								value: tempUser.mobile,
								required: true,
							},
							{
								type: "password",
								name: "password",
								label: <Label number={125} />,
								value: "",
								required: true,
							},
						]}
					/>
				</div>
			</div>
		</div>
	);
}

export default SignUpMobile;
