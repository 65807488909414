import { useContext } from "react";
import GoogleLoginButton from "../components/GoogleLoginButton";
import Label from "../components/Label";

import { Link } from "react-router-dom";
import { AppContext } from "../App";
import Privacy from "../components/Privacy";

function Login() {
	const { settings } = useContext(AppContext);

	return (
		<div className="page">
			<div className="login">
				<div className="content">
					<h1>
						<Label number={3} />
					</h1>
					{settings.google.client_id && <GoogleLoginButton />}
					<Link to="/check_email" className="button button-primary">
						<Label number={35} />
					</Link>
					{settings.customers?.enable_sms_login == 1 && (
						<Link to="/check_mobile" className="button button-primary">
							<Label number={191} />
						</Link>
					)}
					<Privacy />
				</div>
			</div>
		</div>
	);
}

export default Login;
