import { useEffect, useState } from "react";
import Label from "../Label";

function Password({ id, name }) {
	const [type, setType] = useState("password");
	const [value, setValue] = useState("");
	const [errors, setErrors] = useState([false, false, false, false]);
	const passwordLength = 8;

	useEffect(() => {
		checkPassword(); // eslint-disable-next-line
	}, [value]);

	const generatePassword = () => {
		console.log("generatePassword");

		let charCodes = arrayFromLowToHigh(65, 90);
		charCodes = charCodes.concat(arrayFromLowToHigh(97, 122));
		charCodes = charCodes.concat(arrayFromLowToHigh(48, 57));

		const passwordCharacters = [];
		for (let i = 0; i < passwordLength; i++) {
			const characterCode = charCodes[Math.floor(Math.random() * charCodes.length)];
			passwordCharacters.push(String.fromCharCode(characterCode));
		}

		let p = passwordCharacters.join("");
		if (!checkPassword(p)) generatePassword();
		else setValue(p);
	};

	const checkPassword = (p = false) => {
		console.log("checkPassword", p);

		if (!p) p = value;

		const arr = [...errors];
		let valid = true;

		// Validate lowercase letters
		var lowerCaseLetters = /[a-z]/g;
		if (p.match(lowerCaseLetters)) {
			arr[0] = false;
		} else {
			arr[0] = true;
			valid = false;
		}

		// Validate capital letters
		var upperCaseLetters = /[A-Z]/g;
		if (p.match(upperCaseLetters)) {
			arr[1] = false;
		} else {
			arr[1] = true;
			valid = false;
		}

		// Validate numbers
		var numbers = /[0-9]/g;
		if (p.match(numbers)) {
			arr[2] = false;
		} else {
			arr[2] = true;
			valid = false;
		}

		// Validate length
		if (p.length >= passwordLength) {
			arr[3] = false;
		} else {
			arr[3] = true;
			valid = false;
		}
		setErrors(arr);
		console.log("valid", valid);
		return valid;
	};

	const arrayFromLowToHigh = (low, high) => {
		const array = [];
		for (let i = low; i <= high; i++) {
			array.push(i);
		}
		return array;
	};

	return (
		<div className="password-field">
			<input
				type={type}
				id={id}
				name={name}
				value={value}
				onChange={(e) => setValue(e.target.value)}
				required
			/>
			<div className="buttons-group buttons-group-horizontal">
				<button
					type="button"
					onClick={() => setType(type == "password" ? "text" : "password")}
				>
					{type == "password" ? <Label number={135} /> : <Label number={136} />}
				</button>
				<button type="button" onClick={generatePassword}>
					<Label number={137} />
				</button>
			</div>
			<div className="password-errors">
				<Label number={138} />:
				<div className={errors[0] ? "error" : "success"}>
					<Label number={139} />
				</div>
				<div className={errors[1] ? "error" : "success"}>
					<Label number={140} />
				</div>
				<div className={errors[2] ? "error" : "success"}>
					<Label number={141} />
				</div>
				<div className={errors[3] ? "error" : "success"}>
					<Label number={142} />
				</div>
			</div>
		</div>
	);
}

export default Password;
