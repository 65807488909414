export default function Senape({ color = "red" }) {
	const style = { strokeWidth: "0px", fill: color };

	return (
		<svg id="Livello_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
			<path
				d="m164.21,303.57c0-30.4-.07-60.79.03-91.19.05-17.39,4.56-22.42,21.4-27.02,3.36-.92,7.17-4.79,8.37-8.15,4.07-11.4,8.49-21.45,22.3-24.16,2.56-.5,5.1-6.49,5.78-10.27,2.58-14.51,4.41-29.16,6.48-43.77,1.73-12.18,8.46-19.65,20.86-20.61,11.29-.88,19.89,7.4,22,20.75,2.23,14.12,4.11,28.3,5.86,42.49.8,6.55,3.03,10.01,9.79,13.54,7.29,3.81,12.78,12.46,17.08,20.11,3.21,5.71,4.79,10.1,12.77,10.87,12.36,1.19,18.79,9.78,18.82,22.16.14,63.48.24,126.97-.04,190.45-.06,14.78-8.79,22.7-23.98,22.77-41.12.18-82.25.2-123.37-.01-15.8-.08-23.89-8.12-24.04-24.07-.29-31.29-.09-62.59-.09-93.88Z"
				style={style}
			/>
		</svg>
	);
}
